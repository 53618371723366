import { all, call, delay, put, takeLatest } from "redux-saga/effects";

// API
import subscriptionRequest from "@/services/api/subscription";

// REDUX ACTIONS
import {
  actionConstants,
  requestUpdateSubscriptionComplete,
  resetSubscriptionStatusMessage,
  setSubscriptionList,
} from "./action";
import { setCurrentUser } from "../auth/action";

// HELPERS
import { trackEvent } from 'helpers/analytics';

function* getSubscriptionRequest(action) {
  try {
    const response = yield call(subscriptionRequest.getSubscriptions);
    if (response.data.data) {
      yield put(setSubscriptionList(response.data.data));
    }
  } catch (error) {
    console.log("getSubscriptionRequest error", error);
  }
}

function* createSubscriptionRequest(action) {
  try {
    const { data } = action;
    const response = yield call(subscriptionRequest.createSubscriptions, data);
    yield put(setCurrentUser(response.data));
  } catch (error) {
    console.log("createSubscriptionRequest error", error);
  }
}

function* updateSubscriptionRequest(action) {
  try {
    const { data } = action;
    const response = yield call(subscriptionRequest.updateSubscriptions, data);
    if (response && response.data) {
      yield put(setCurrentUser(response.data));
      yield put(requestUpdateSubscriptionComplete());
      if (response.data && response.data.subscription && response.data.subscription.items && response.data.subscription.items.data) {
        const currentItem = response.data.subscription.items.data[0];
        const amount = currentItem?.plan?.amount * 0.1;
        yield call(trackEvent, {
          revenue: amount,
          currency: 'USD'
        })
      }

      yield delay(10000);
      yield put(resetSubscriptionStatusMessage());


    }

  } catch (error) {
    console.log("updateSubscriptionRequest error", error);
  }
}

function* updatePaymentMethodRequest(action) {
  try {
    const { data } = action;
    const response = yield call(subscriptionRequest.updatePaymentMethod, data);
    if (response && response.data) {
      yield put(setCurrentUser(response.data));
    }
    yield delay(10000);
    yield put(resetSubscriptionStatusMessage());
    yield call(trackEvent, {})
  } catch (error) {
    console.log("updatePaymentMethodRequest error", error);
  }
}

function* updatePaymentDetailsRequest(action) {
  try {
    const { data } = action;
    const response = yield call(subscriptionRequest.updatePaymentDetails, data);
    if (response && response.data && response.data.user) {
      yield put(setCurrentUser(response.data.user));
      yield call(trackEvent, {});
    }
  } catch (error) {
    console.log("updatePaymentDetailsRequest error", error);
  }
}

function* deleteSubscriptionRequest(action) {
  try {
    const { data } = action;

    const response = yield call(
      subscriptionRequest.deleteSubscriptionRequest,
      data
    );

    if (response && response.data) {
      yield put(setCurrentUser(response.data));
      yield call(trackEvent, {})
    }
  } catch (error) {
    console.log("deleteSubscriptionRequest error", error);
  }
}

export default function* subcriptionSaga() {
  yield all([
    takeLatest(actionConstants.REQUEST_SUBSCRIPTIONS, getSubscriptionRequest),
    takeLatest(
      actionConstants.REQUEST_CREATE_SUBSCRIPTIONS,
      createSubscriptionRequest
    ),
    takeLatest(
      actionConstants.REQUEST_UPDATE_SUBSCRIPTIONS,
      updateSubscriptionRequest
    ),
    takeLatest(
      actionConstants.REQUEST_CANCEL_SUBSCRIPTIONS,
      deleteSubscriptionRequest
    ),
    takeLatest(
      actionConstants.REQUEST_UPDATE_PAYMENT_METHOD,
      updatePaymentMethodRequest
    ),
    takeLatest(
      actionConstants.REQUEST_UPDATE_PAYMENT_DETAILS,
      updatePaymentDetailsRequest
    ),
  ]);
}
