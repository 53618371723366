const actionConstants = {
  REQUEST_SUBSCRIPTIONS: "REQUEST_SUBSCRIPTIONS",
  REQUEST_CREATE_SUBSCRIPTIONS: "REQUEST_CREATE_SUBSCRIPTIONS",
  REQUEST_CANCEL_SUBSCRIPTIONS: "REQUEST_CANCEL_SUBSCRIPTIONS",
  REQUEST_UPDATE_SUBSCRIPTIONS: "REQUEST_UPDATE_SUBSCRIPTIONS",
  REQUEST_UPDATE_PAYMENT_METHOD: "REQUEST_UPDATE_PAYMENT_METHOD",
  REQUEST_UPDATE_PAYMENT_DETAILS: "REQUEST_UPDATE_PAYMENT_DETAILS",
  SET_SUBSCRIPTION_LIST: "SET_SUBSCRIPTION_LIST",
  REQUEST_UPDATE_SUBSCRIPTION_COMPLETE: "REQUEST_UPDATE_SUBSCRIPTION_COMPLETE",
  RESET_SUBSCRIPTION_STATUS_MESSAGE: "RESET_SUBSCRIPTION_STATUS_MESSAGE"
};

const requestSubscriptions = () => ({
  type: actionConstants.REQUEST_SUBSCRIPTIONS,
});

const requestUpdateSubscriptions = <T>(data: T) => ({
  type: actionConstants.REQUEST_UPDATE_SUBSCRIPTIONS,
  data,
});

const requestCancelSubscriptions = <T>(data: T) => ({
  type: actionConstants.REQUEST_CANCEL_SUBSCRIPTIONS,
  data,
});

const requestCreateSubscriptions = <T>(data: T) => ({
  type: actionConstants.REQUEST_CREATE_SUBSCRIPTIONS,
  data,
});

const setSubscriptionList = <T>(data: T) => ({
  type: actionConstants.SET_SUBSCRIPTION_LIST,
  data,
});

const requestUpdatePaymentMethod = <T>(data: T) => ({
  type: actionConstants.REQUEST_UPDATE_PAYMENT_METHOD,
  data,
});

const requestUpdatePaymentDetails = <T>(data: T) => ({
  type: actionConstants.REQUEST_UPDATE_PAYMENT_DETAILS,
  data,
});

const requestUpdateSubscriptionComplete = () => ({
  type: actionConstants.REQUEST_UPDATE_SUBSCRIPTION_COMPLETE,
});

const resetSubscriptionStatusMessage = () => ({
  type: actionConstants.RESET_SUBSCRIPTION_STATUS_MESSAGE,
});

export {
  actionConstants,
  requestSubscriptions,
  requestCancelSubscriptions,
  requestCreateSubscriptions,
  requestUpdatePaymentMethod,
  requestUpdateSubscriptions,
  requestUpdatePaymentDetails,
  setSubscriptionList,
  requestUpdateSubscriptionComplete,
  resetSubscriptionStatusMessage
};
