import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";

// API
import authRequest from '@/services/api/auth';

// REDUX ACTIONS
import {
  actionConstants,
  requestSignUpComplete,
  requestSignInFailed,
  requestUpdateFailed,
  requestUpdateUserComplete,
  requestChangePasswordComplete,
  resetStatusMessage,
  setCurrentUser
} from './action';
import { setUserList } from 'redux/users/action'

// HELPERS
import { currencyToNumber } from 'helpers/index';
import { trackEvent } from 'helpers/analytics';
import { clearToken, setToken } from 'helpers/token';

export const getUsers = (state) => state.user;

function* getSignInRequest(action) {
  try {
    const { data, isAdmin } = action;
    const response = yield call(authRequest[isAdmin ? 'adminSignIn' : 'signIn'], data);
    yield all([
      put(setCurrentUser(response.data.user)),
      call(setToken, response.data.user.access_token)
    ]);
  } catch (error) {

    if (error && error.response) {
      yield put(requestSignInFailed(error.response.data));
    }

  }
}

function* getCurrentUserRequest(action) {
  try {
    const response = yield call(authRequest.getCurrentUser);
    if (response.data) {
      yield put(setCurrentUser(response.data.user));
    } else {
      yield put(setCurrentUser(null));
    }
  } catch (error) {

    yield put(setCurrentUser(null));
  }
}


function* getSignUpRequest(action) {
  try {
    const { data } = action;
    console.log('getSignUpRequest data', data)


    const response = yield call(authRequest.signUp, data);
    console.log('getSignUpRequest response', response)
    yield put(requestSignUpComplete());
    yield delay(10000);
    yield put(resetStatusMessage());
    const amount = currencyToNumber(data.subscription.amount);
    yield call(trackEvent, {
      revenue: amount,
      currency: 'USD'
    })
  } catch (error) {
    yield put(requestSignInFailed(error.response.data));
  }
}


function* getLogoutRequest(action) {
  try {
    yield call(clearToken);
    yield put(setCurrentUser(null));
  } catch (error) {

  }
}

function* updateUserRequest(action) {
  try {
    const { data, updateBy } = action;
    const response = yield call(authRequest.updateUser, data);

    if (updateBy === 'admin' && response && response.data && response.data.user) {
      let users = yield select(getUsers);
      users = users.list.map(usr => {
        if (usr.auth_id === response.data.user.auth_id) {
          return {
            ...usr,
            ...response.data.user
          }
        }
        return {
          ...usr
        }
      });
      yield put(setUserList(users));
    }
    else {
      yield put(setCurrentUser(response.data.user));
      yield put(requestUpdateUserComplete());
    }
    yield delay(10000);
    yield put(resetStatusMessage());

  } catch (error) {

    yield put(requestUpdateFailed(error));
  }
}

function* updateUserPasswordRequest(action) {
  try {
    const { data } = action;
    const response = yield call(authRequest.updateUserPassword, data);
    yield all([
      put(setCurrentUser(response.data.user)),
      put(requestChangePasswordComplete()),
      call(setToken, response.data.user.access_token),

    ]);

    yield delay(10000);
    yield put(resetStatusMessage());
  } catch (error) {
    yield put(requestUpdateFailed(error.response.data));
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(actionConstants.REQUEST_SIGN_IN, getSignInRequest),
    takeLatest(actionConstants.REQUEST_CURRENT_USER, getCurrentUserRequest),
    takeLatest(actionConstants.REQUEST_SIGN_UP, getSignUpRequest),
    takeLatest(actionConstants.REQUEST_UPDATE_USER, updateUserRequest),
    takeLatest(actionConstants.REQUEST_LOGOUT, getLogoutRequest),
    takeLatest(actionConstants.REQUEST_CURRENT_USER_PASSWORD, updateUserPasswordRequest)
  ]);

}
