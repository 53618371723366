import { addDays, format, fromUnixTime } from "date-fns";

const DATE_FORMAT = "MMM dd, yyyy";

const addDaysToDate = (date, days = 1) => {
  return format(addDays(date, days), DATE_FORMAT);
};

const convertTimestamp = (timestamp) => {
  return timestamp ? format(fromUnixTime(timestamp), DATE_FORMAT) : "";
};

const setCurrencyFormat = (value) => {
  return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
};

const currencyToNumber = value =>  parseFloat(value.replace(/\$/g,''))

export { addDaysToDate, convertTimestamp, setCurrencyFormat, currencyToNumber };
