import { useTranslation } from "react-i18next";

const FAQ = () => {
  const [t] = useTranslation('common');
  const QA = [
    {
      question: t('faq.question_1'),
      answer: t('faq.answer_1'),
    },
    {
      question: t('faq.question_2'),
      answer: t('faq.answer_2'),
    },
    {
      question: t('faq.question_3'),
      answer: t('faq.answer_3'),
    },
    {
      question: t('faq.question_4'),
      answer: t('faq.answer_4'),
    },
    {
      question: t('faq.question_5'),
      answer: t('faq.answer_5'),
    },
    {
      question: t('faq.question_6'),
      answer: t('faq.answer_6'),
    },
    {
      question: t('faq.question_7'),
      answer: t('faq.answer_7'),
    },
  ];

  return (
    <div className="flex flex-col mt-20 bg-white md:px-12">
      <div className="text-center px-8 md:px-12">
        <h2 className="text-3xl font-semibold  text-gray-800 md:text-4xl">
          {t('faq.title')}
        </h2>
      </div>
      <div className="flex flex-wrap w-full mt-12 lg:text-left md:px-20">
        <div className="shadow-md">
          {QA.map((faq, index) => {
            return (
              <div className="tab w-full overflow-hidden border-t">
                <input
                  className="absolute opacity-0"
                  id={`tab-single-${index}`}
                  type="radio"
                  name="tabs2"
                />
                <label
                  className="font-semibold block p-5 leading-normal cursor-pointer"
                  htmlFor={`tab-single-${index}`}
                >
                  {faq.question}
                </label>
                <div className="tab-content overflow-hidden border-l-2 border-green-700 leading-normal">
                  <p className="p-5 text-xl">{faq.answer}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
