import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import AdminRoutes from "./AdminRoutes";
import Routes from "./Routes";

import { AuthProps } from "types/interface";

// REDUX
import { requestCurrentUser, requestLogout } from "redux/auth/action";
import { requestSubscriptions } from "redux/subscription/action";

// COMPONENTS
import Header from "components/Header";


function App() {
  const isAdmin = window.location.pathname.includes("admin");
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { auth } = useSelector((state: AuthProps) => {
    return {
      auth: state.auth,
    };
  });

  useEffect(() => {
    dispatch(requestCurrentUser());
    dispatch(requestSubscriptions());
  }, []);

  useEffect(() => {
    if (auth.user && auth.user.auth_id) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [auth.user]);

  const handleLogOut = () => {
    dispatch(requestLogout());
  };


  return (
    <div className="h-full">
      <Router>
        {!isAdmin && (
          <Header
            isAuthenticated={isAuthenticated}
            handleLogOut={handleLogOut}
          />
        )}

        <main className="h-full flex-1 font-sans overflow-y-auto ">
          {isAdmin ? (
            <AdminRoutes isAuthenticated={isAuthenticated} />
          ) : (
            <Routes isAuthenticated={isAuthenticated} />
          )}
        </main>
      </Router>
    </div>
  );
}

export default App;
