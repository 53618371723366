import { combineReducers } from 'redux';
import auth from './auth/reducer';
import subscriptions from './subscription/reducer';
import user from './users/reducer';

const reducers = combineReducers({
	auth,
	subscriptions,
	user
});

const rootReducer = (state, action) => {
	switch (action.type) {
		default:
			return reducers(state, action);
	}
};

export default rootReducer;