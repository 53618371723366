import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { DefaultProps } from 'types/type'

const NonProtectedRoute = ({ children,location, isAuthenticated, isAdmin = false, ...rest }: DefaultProps) => {
	return (
		<Route {...rest}>
			{!isAuthenticated ? (
        children
			) : (
				<Redirect
					to={{
						pathname: isAdmin ? '/admin' : '/',
						state: {
							from: location
						}
					}}
				/>
			)}
		</Route>
	);

};

export default NonProtectedRoute;
