import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "pages/Home";
import SignIn from "pages/Auth/SignIn";
import SignUp from "pages/Auth/SignUp";
import ForgotPassword from "pages/Auth/ForgotPassword";
import Profile from "pages/Profile";

import NonProtectedRoute from "components/Route/NonProtectedRoute";
import ProtectedRoute from "components/Route/ProtectedRoute";

import { DefaultProps } from "types/type";

const Routes = (props: DefaultProps) => {
  const { handleMobileToggle, isMobileMenuToggleOpen, isAuthenticated } = props;

  return (
    <Switch>
      <Route
        exact
        path="/"
        component={(componentProps) => (
          <Home
            {...componentProps}
            handleMobileToggle={handleMobileToggle}
            isMobileMenuToggleOpen={isMobileMenuToggleOpen}
          />
        )}
      />
      <ProtectedRoute exact path="/profile" isAuthenticated={isAuthenticated}>
        <Profile {...props} />
      </ProtectedRoute>
      <NonProtectedRoute exact path="/signup" isAuthenticated={isAuthenticated}>
        <SignUp {...props} />
      </NonProtectedRoute>
      <NonProtectedRoute exact path="/signin" isAuthenticated={isAuthenticated}>
        <SignIn {...props} />
      </NonProtectedRoute>
      <NonProtectedRoute exact path="/resetpassword" isAuthenticated={isAuthenticated}>
        <ForgotPassword {...props} />
      </NonProtectedRoute>
    </Switch>
  );
};

export default Routes;
