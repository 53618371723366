type Props = {
  handleSidebarToggle(): void;
};
const Navbar = (props: Props) => {
  const { handleSidebarToggle } = props;
  return (
    <div className="top-bar-container py-2 flex border-b items-center justify-between">
      <button
        className="flex items-center justify-center p-2 mx-2 border rounded"
        onClick={handleSidebarToggle}
      >
        <svg
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          className="fill-current h-4 w-4"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </button>
    </div>
  );
};

export default Navbar;
