import { actionConstants } from "./action";

const initialState = {
  isLoading: false,
  isSubscriptionUpdateSuccess: false,
  error: {},
  list: [],
};

const subscriptions = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.REQUEST_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case actionConstants.REQUEST_CREATE_SUBSCRIPTIONS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case actionConstants.SET_SUBSCRIPTION_LIST:
      return {
        ...state,
        list: action.data,
        error: {},
        isLoading: false,
      };
    case actionConstants.REQUEST_UPDATE_SUBSCRIPTIONS:
      return {
        ...state,
        isSubscriptionUpdateSuccess: false,
        error: {},
      };
    case actionConstants.REQUEST_UPDATE_SUBSCRIPTION_COMPLETE:
      return {
        ...state,
        isSubscriptionUpdateSuccess: true,
        error: {},
      };
    case actionConstants.RESET_SUBSCRIPTION_STATUS_MESSAGE:
      return {
        ...state,
        isSubscriptionUpdateSuccess: false
      }
    default:
      return state;
  }
};

export default subscriptions;
