import { useTranslation } from "react-i18next";
// import Manage from "@/assets/svg/manage.svg";
// import Sync from "@/assets/svg/sync.svg";
// import CustomizableCommunication from "@/assets/svg/customizable_communication.svg";
// import ReverseRepopulation from "@/assets/svg/reverse_repopulation.svg";
// import VoiceMail from "@/assets/svg/voicemail_landline.svg";

const Features = () => {
  const [t] = useTranslation('common');
  return (
    <div
      id="feature"
      className="flex flex-col mt-8 items-center px-2 md:px-12 h-1/4"
    >
      <div className="text-center lg:text-left px-2 md:px-12">
        <h2 className="text-3xl font-semibold text-center text-gray-800 md:text-4xl">
          {t('feature_header.title')}
        </h2>
      </div>

      <div className="flex flex-wrap justify-evenly mt-2 md:mt-12 lg:text-left px-0 md:px-12">
      <div className="px-2 md:px-4 py-2 md:py-12 w-full  md:w-2/6">
          {/* <img alt="contacts" className="mx-auto w-10 h-auto" src={Sync} /> */}
          <h5 className="text-1xl mt-2 font-bold text-center text-gray-700 md:text-1xl">
          {t('feature_header.feature_1_title')}
          </h5>
          <p className="text-gray-400">
          {t('feature_header.feature_1_subtitle')}
            
          </p>
          <ul className="text-gray-400 text-sm p-2">
            <li> ● {t('feature_header.feature_1_item_1')}</li>
            <li> ● {t('feature_header.feature_1_item_2')}</li>
            <li> ●{t('feature_header.feature_1_item_3')}</li>
          </ul>
        </div>
        <div className="px-2 md:px-4 py-2 md:py-12 w-full  md:w-2/6">
          <h5 className="text-1xl mt-2 font-bold text-center text-gray-700 md:text-1xl">
          {t('feature_header.feature_2_title')}
          </h5>
          <p className="text-gray-400">
            {t('feature_header.feature_2_subtitle')}
          </p>
          <ul className="text-gray-400 text-sm p-2">
            <li> ● {t('feature_header.feature_2_item_1')}</li>
            <li> ● {t('feature_header.feature_2_item_2')}</li>
            <li> ● {t('feature_header.feature_2_item_3')}</li>
          </ul>
        </div>
        <div className="px-2 md:px-4 py-2 md:py-12 w-full  md:w-2/6">
          <h5 className="text-1xl mt-2 font-bold text-center text-gray-700 md:text-1xl">
          {t('feature_header.feature_3_title')}
          </h5>
          <p className="text-gray-400">
          {t('feature_header.feature_3_subtitle')}
          </p>
          <ul className="text-gray-400 text-sm p-2">
            <li> ● {t('feature_header.feature_3_item_1')}</li>
            <li> ● {t('feature_header.feature_3_item_2')}</li>
            <li> ● {t('feature_header.feature_3_item_3')}</li>
          </ul>
        </div>
        <div className="px-10 md:px-4 pt-2 pb-8 w-full md:w-4/5 ">
     
          <h5 className="text-1xl mt-2 font-bold text-center text-gray-700 md:text-1xl">
          {t('feature_header.feature_4_title')}
          </h5>
          <p className="text-gray-400">
          {t('feature_header.feature_4_subtitle')}
          </p>

          <div className="flex flex-wrap w-full">
            <div className="w-full md:w-1/2">
            <ul className="text-gray-400 text-sm p-2">
            <li> ● <b>{t('feature_header.feature_4_item_1')}</b>
            <div>{t('feature_header.feature_4_item_1_sub')}</div>
            </li>
            <li> ● <b>{t('feature_header.feature_4_item_2')}</b>
          
              <div>{t('feature_header.feature_4_item_2_sub')}</div>
            </li>
            <li> ● {t('feature_header.feature_4_item_3')}
              <div>{t('feature_header.feature_4_item_3_sub')}</div>
            </li>
            <li> ● {t('feature_header.feature_4_item_4')}
              <div>{t('feature_header.feature_4_item_4_sub')}</div>
            </li>
            </ul>
          </div> 


          <div className="w-full md:w-1/2">
            <ul className="text-gray-400 text-sm p-2">
            <li> ● <b>{t('feature_header.feature_4_item_5')}</b>
              <div>{t('feature_header.feature_4_item_5_sub')}</div>
            </li>
            <li> ● <b>{t('feature_header.feature_4_item_6')}</b>
              <div>{t('feature_header.feature_4_item_6_sub')}</div>

            </li>
            <li> ● <b>{t('feature_header.feature_4_item_7')}</b>
              <div>{t('feature_header.feature_4_item_7_sub')}</div>
            </li>
            <li> ● <b>{t('feature_header.feature_4_item_8')}</b>
              <div>{t('feature_header.feature_4_item_8_sub')}</div>
            </li>
          </ul>
              </div> 

          </div>
        </div>
        {/* <div className="content-center text-center sm:px-10 md:px-20 py-12 md:w-1/3 sm:w-full ">
          <img alt="email" className="mx-auto w-10 h-auto" src={VoiceMail} />
          <h5 className="text-1xl mt-2 font-semibold text-center text-gray-700 md:text-1xl">
            Voicemail to Landline
          </h5>
          <p className="text-gray-400">
            Use the voice call feature to notify contacts with only landline
            access that your contact information has changes
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Features;
