import axiosInstance from "../index";

import { getToken } from "helpers/token";

const userRequest = {
  getUsers: () => {
    const token = getToken();
    return axiosInstance.get("user/list", {
      headers: {
        token,
      },
    });
  },
  deleteUser: (id: string, token: string = getToken()) => {
    return axiosInstance.delete("user/delete", {
      data: {
        auth_id: id,
      },
      headers: {
        token,
      },
    });
  },
};

export default userRequest;
