import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import HeaderLogo from "@/assets/header_logo.png";

// SHARED COMPONENTS
import InputForm from "components/Forms/Input";
import SelectForm from "components/Forms/Select";
import CheckoutForm from "components/Stripe/CheckoutForm";
import Card from "components/Stripe/Card";

// REDUX ACTIONS
import { requestSignUp } from "redux/auth/action";
import { requestSubscriptions } from "redux/subscription/action";

// CONSTANTS
import OPTION_COUNTRIES from "constants/countries";
import {
  OPTION_SUBSCRIPTION,
  OPTION_TIER_SUBSCRIPTION,
} from "constants/subscriptions";

interface Inputs {
  email: string;
  password: string;
  confirm_password: string;
  firstname: string;
  birthdate: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
}

const SignUp = (props: any) => {
  const [t] = useTranslation('common');

  const dispatch = useDispatch();
  let history = useHistory();
  // const queries = useParams();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    mode: "onSubmit",
  });
  const { auth, subscriptions } = useSelector<T>((state: T) => {
    return {
      auth: state.auth,
      subscriptions: state.subscriptions.list,
    };
  });
  const stripe = useStripe();
  const elements = useElements();
  const [selectedSubscription, setSelectedSubscription] = useState<T>("basic");
  const [selectedTier, setSelectedTier] = useState<T>("basic_tier_1");
  const [selectedDialCode, setSelectedDialCode] = useState("+1");
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("+1");
  const [currentZipCode, setCurrentZipCode] = useState("");
  const [signUpError, setSignUpError] = useState<Object | Nullable>(null);
  // const [isLoading, setIsLoading] = useState(false);
  const password = useRef({});

  const translatedSubscriptionTierOption =  OPTION_TIER_SUBSCRIPTION[selectedSubscription] ? OPTION_TIER_SUBSCRIPTION[selectedSubscription].map(item => {
    return {
      ...item,
      label: t(item.label)
    }
  }) : [];

  const translatedSubscriptionOption =  OPTION_SUBSCRIPTION.map(item => {
    return {
      ...item,
      label: t(item.label)
    }
  }) 

  password.current = watch("password", "");

  useEffect(() => {
    dispatch(requestSubscriptions());
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let subscription = params.get("subscription");
    let tier = params.get("tier");

    if (subscription) {
      setSelectedSubscription(subscription);
      setSelectedTier(
        tier ? tier : OPTION_TIER_SUBSCRIPTION[subscription][0].value
      );
    }
  }, []);

  useEffect(() => {
    if (auth.error) {
      setSignUpError(auth.error);
    }
  }, [auth.error]);

  useEffect(() => {
    if (auth.isSignupSuccess) {
      reset();
      //setIsLoading(false);
      setTimeout(() => {
        history.push("/signin");
      }, 1000);
    }
  }, [auth.isSignupSuccess]);

  const handleChangeSubscription = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setSelectedSubscription(value);
    setSelectedTier(OPTION_TIER_SUBSCRIPTION[value][0].value);
  };

  const handleChangeSubscriptionTier = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;

    setSelectedTier(value);
  };

  const handleChangeCountry = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    const currentCountry = OPTION_COUNTRIES.find(
      (country) => country.value === value
    );
    setSelectedDialCode(currentCountry.dial_prefix);
    setCurrentPhoneNumber(currentCountry.dial_prefix);
  };

  const handleChangeZipCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 5) {
      setCurrentZipCode(value);
    }
  };

  const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === "" || !value.includes(selectedDialCode)) {
      setCurrentPhoneNumber(selectedDialCode);
    } else {
      setCurrentPhoneNumber(value);
    }
  };
  const onSubmit = async function <T>(data: T) {
    const cardElement = elements.getElement(CardElement);
    const response = await stripe.createToken(cardElement);
    console.log('On Submit Data', data)
    console.log('On Submit cardElement', cardElement)
    console.log('On Submit Response', response)
    console.log('On Submit selectedSubscription', selectedSubscription)
    if (subscriptions) {
      const currentSubs = subscriptions.find(
        (item) =>
          item.name.toLowerCase().replace(" ", "_") === selectedSubscription
      );

      const currentTier = currentSubs.plans.find(
        (item) => item.nickname === selectedTier
      );
      if (currentTier) {
        const payload = {
          user: {
            ...data,
          },
          subscription: currentTier,
          payment: response.token,
        };

        dispatch(requestSignUp(payload));
      }
    }
  };

  return (
    <div className="bg-auth p-4 flex flex-wrap justify-center w-full ">
      <div className="mx-auto my-auto sm:px-4 md:px-10 py-5 w-full md:w-3/4 lg:w-2/5 lg:w-2/5">
        <div className="bg-white mt-32 border-2 border-gray-100 px-5 py-16 rounded-md tracking-wide shadow-lg">
          <div id="header" className="flex">
            <div id="body" className="flex flex-col w-full px-6">
              <div className="w-full pb-2">
                <img
                  alt="logo"
                  src={HeaderLogo}
                  className="w-36 md:w-44 mx-auto h-auto"
                />
              </div>
              <div className="w-full my-6">
                <div className="flex w-full my-3 text-red-700">
                  {signUpError?.message}
                </div>
                <h4 className="font-semibold text-gray-800 md:text-2xl">
                  {t('signup.title')}
                </h4>
              </div>

              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
              >
                <div className="w-full mx-auto mt-6">
                  <InputForm
                    type="email"
                    error={errors.email ? true : false}
                    errorMessage={errors?.email?.message}
                    register={register("email", {
                      required: true,
                      pattern: {
                        value: /\S+@\S+.\S+/,
                        message:  t('signup.email_error'),
                      },
                    })}
                    label={<span className="text-base">{t('signup.email')}</span>}
                    placeholder=" "
                  />
                </div>

                <div className="flex justify-between w-full mt-6">
                  <div className="w-1/2 my-3">
                    <InputForm
                      type="password"
                      error={errors.password ? true : false}
                      errorMessage={errors.password && errors.password.message}
                      register={register("password", {
                        required: t('signup.password_error'),
                        minLength: {
                          value: 8,
                          message: "Password must have at least 8 characters",
                        },
                      })}
                      label={<span className="text-base">{t('signup.password')}</span>}
                      placeholder=" "
                    />
                  </div>

                  <div className="w-1/2 my-3">
                    <InputForm
                      type="password"
                      error={errors.confirm_password ? true : false}
                      errorMessage={
                        errors.confirm_password &&
                        errors.confirm_password.message
                      }
                      register={register("confirm_password", {
                        validate: (value) =>
                          value === password.current ||
                          t('signup.password_not_match'),
                      })}
                      label={
                        <span className="text-base">{t('signup.confirm_password')}</span>
                      }
                      placeholder=" "
                    />
                  </div>
                </div>

                <div className="flex justify-between w-full mt-6">
                  <div className="w-1/2 my-3">
                    <InputForm
                      error={errors.firstname ? true : false}
                      errorMessage={t('signup.required')}
                      register={register("firstname")}
                      label={<span className="text-base">{t('signup.firstname')}</span>}
                      placeholder=" "
                    />
                  </div>

                  <div className="w-1/2 my-3">
                    <InputForm
                      error={errors.lastname ? true : false}
                      errorMessage={t('signup.required')}
                      register={register("lastname", { required: true })}
                      label={<span className="text-base">{t('signup.lastname')}</span>}
                      placeholder=" "
                    />
                  </div>
                </div>

                <div className="w-full mx-auto mt-6">
                  <InputForm
                    type="date"
                    error={errors.birthdate ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("birthdate", { required: true })}
                    label={<span className="text-base">{t('signup.birthdate')}</span>}
                    placeholder=" "
                  />
                </div>

                <div className="w-full mx-auto mt-6">
                  <SelectForm
                    options={OPTION_COUNTRIES}
                    error={errors.country ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("country", { required: true })}
                    label={<span className="text-base">{t('signup.country')}</span>}
                    placeholder=" "
                    onChange={handleChangeCountry}
                  />
                </div>

                <div className="w-full mx-auto mt-6">
                  <InputForm
                    value={currentPhoneNumber}
                    error={errors.current_phone_number ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("current_phone_number", {
                      required: true,
                    })}
                    label={<span className="text-base">{t('signup.phone_number')}</span>}
                    placeholder=" "
                    onChange={handleChangePhoneNumber}
                  />
                </div>

                <div className="w-full mx-auto mt-6">
                  <InputForm
                    error={errors.address ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("address", { required: true })}
                    label={<span className="text-base">{t('signup.address')}</span>}
                    placeholder=" "
                  />
                </div>

                <div className="w-full mx-auto mt-6">
                  <InputForm
                    error={errors.city ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("city", { required: true })}
                    label={<span className="text-base">{t('signup.city')}</span>}
                    placeholder=" "
                  />
                </div>

                <div className="w-full mx-auto mt-6">
                  <InputForm
                    error={errors.state ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("state", { required: true })}
                    label={<span className="text-base">{t('signup.state')}</span>}
                    placeholder=" "
                  />
                </div>

                <div className="w-full mx-auto mt-6">
                  <InputForm
                    value={currentZipCode}
                    error={errors.zip ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("zip", {
                      required: true,
                      maxLength: {
                        value: 5,
                        message: "Zipcode should have a maximum length of 5",
                      },
                    })}
                    label={<span className="text-base">{t('signup.zip_code')}</span>}
                    placeholder=" "
                    onChange={handleChangeZipCode}
                  />
                </div>

                <div className="w-full mx-auto mt-8">
                  <SelectForm
                    defaultValue={selectedSubscription}
                    options={translatedSubscriptionOption}
                    error={errors.subscription ? true : false}
                    errorMessage={t('signup.required')}
                    register={register("subscription", { required: true })}
                    label={<span className="text-base">{t('signup.subscription')}</span>}
                    placeholder=" "
                    onChange={handleChangeSubscription}
                  />
                </div>

                {selectedSubscription && (
                  <div className="w-full mx-auto mt-8">
                    <SelectForm
                      defaultValue={selectedTier}
                      options={translatedSubscriptionTierOption}
                      error={errors.tier ? true : false}
                      errorMessage={t('signup.required')}
                      register={register("tier", { required: true })}
                      label={<span className="text-base">Tier</span>}
                      placeholder=" "
                      onChange={handleChangeSubscriptionTier}
                    />
                  </div>
                )}

                <div className="w-full mx-auto mt-6">
                  <Card />
                </div>

                <div className="flex w-full mt-6">
                  <button
                    disabled={auth.isLoading}
                    className="mx-auto w-full cursor-pointer px-8 py-2 bg-custom-purple text-sm text-gray-200 font-light rounded-full"
                  >
                    {auth.isLoading ?  t('profile.please_wait') : t('signup.title').toUpperCase()}
                  </button>
                </div>

                <div className="flex w-full my-3">
                  <span className="mx-auto text-xs font-light">
                    {t('signup.already_have_an_account')}?{" "}
                    <Link
                      className="cursor-pointer text-custom-purple"
                      to="/signin"
                    >
                      {t('signin.title').toUpperCase()}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm(SignUp);
