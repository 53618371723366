import User from "@/assets/svg/user-profile.svg";

type Props = {
  user: Nullable<Object>;
};

const Cover = (props: Props) => {
  const { user } = props;
  return (
    <div className="mx-auto h-auto w-11/12 md:w-4/5">
      <div className="flex flex-items rounded-lg shadow-lg bg-profile_cover w-full flex flex-row flex-wrap p-3 antialiased">
        <div className="w-1/4 mx-12 block md:relative md:top-12 md:left-20 md:w-56">
          <img
            alt="profile"
            className="rounded-full bg-white p-4 shadow-lg antialiased"
            src={User}
          />
        </div>
        <div className="flex flex-col-reverse w-full mx-12 align-bottom md:w-2/4 px-3 flex flex-row flex-wrap">
          <div className="text-base md:text-xl text-gray-200 align-bottom">
            {user && user.phone_number}
          </div>
          <div className="text-base md:text-xl text-gray-200 align-bottom">
            {user && user.email}
          </div>
          <div className="text-base md:text-3xl font-semibold text-white align-bottom">
            {user && user.firstname} {user && user.lastname}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cover;
