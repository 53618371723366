import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { format, fromUnixTime } from "date-fns";

// SHARED COMPONENTS
import Modal from "components/Modal";
import Table from "components/Table";
import InputForm from "components/Forms/Input";
import SelectForm from "components/Forms/Select";
import Spinner from "components/Spinner";
import Edit from "components/Icons/Edit";
import Delete from "components/Icons/Delete";

// CONSTANTS
import OPTION_COUNTRIES from "constants/countries";
import { OPTION_TIERS } from "constants/subscriptions";

// REDUX ACTIONS
import { requestUpdateUser } from "redux/auth/action";
import { requestDeleteUser, requestUsers } from "redux/users/action";

const DATE_FORMAT = "MMM dd, yyyy";
const INPUT_DATE_FORMAT = "yyyy-MM-dd";

function convertTimestamp(timestamp) {
  return timestamp ? format(fromUnixTime(timestamp), DATE_FORMAT) : "";
}

interface Inputs {
  // email: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  phone_number: string;
  // address: string;
  // city: string;
  // state: string;
  // zipcode: string;
}

const Users = (props: any) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: AuthProps) => {
    return {
      user: state.user,
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [userList, setUserList] = useState([]);
  const [isUserDetailModalVisible, setIsUserDetailModalVisible] = useState(
    false
  );
  const [isUserDeleteModalVisible, setIsUserDeleteModalVisible] = useState(
    false
  );
  const [selectedUser, setSelectedUser] = useState<Object | Nullable>("");
  const [selectedUserId, setSelectedUserId] = useState<String | Nullable>("");

  const columns = useMemo(
    () => [
      {
        Header: `Total Users: ${userList.length}`,
        columns: [
          {
            Header: "Firstname",
            accessor: "firstname",
          },
          {
            Header: "Lastname",
            accessor: "lastname",
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "Subscription",
            accessor: "subscription.plan.nickname",
            Cell: ({ cell }) => {
              const currentSubscription =
                cell.row.original &&
                cell.row.original.subscription &&
                cell.row.original.subscription.plan &&
                OPTION_TIERS.find(
                  (item) =>
                    item.value === cell.row.original.subscription.plan.nickname
                );
              return (
                <div className="flex item-center ">
                  {currentSubscription ? currentSubscription.label : ""}
                </div>
              );
            },
          },
          {
            Header: "Period End",
            accessor: "subscription.current_period_end",
            Cell: ({ cell }) => {
              return (
                <div className="flex item-center ">
                  {cell.row.original.subscription &&
                    convertTimestamp(
                      cell.row.original.subscription.current_period_end
                    )}
                </div>
              );
            },
          },
          {
            Header: "Subscription Status",
            accessor: "subscription.status",
          },
          {
            Header: "Country",
            accessor: "country",
          },
          {
            Header: "Action",
            Cell: ({ cell }) => (
              <div className="flex item-center ">
                <div
                  onClick={() => {
                    handleSelectedUser(cell.row.original);
                  }}
                  className="cursor-pointer w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                >
                  <Edit />
                </div>
                <div
                  onClick={() => {
                    setSelectedUserId(cell.row.original.auth_id);
                    handleUserDeleteModal();
                  }}
                  className="cursor-pointer w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                >
                  <Delete />
                </div>
              </div>
            ),
          },
        ],
      },
    ],
    [userList]
  );

  useEffect(() => {
    dispatch(requestUsers());
  }, []);

  useEffect(() => {
    if (user.list) {
      setUserList(user.list);
    }
  }, [user.list]);

  const onSubmit = (data: any) => {
    const payload = {
      ...selectedUser,
      ...data,
    };

    dispatch(requestUpdateUser(payload, "admin"));
    setIsUserDetailModalVisible(false);
  };

  const handleSelectedUser = (data) => {
    setSelectedUser(data);
    setIsUserDetailModalVisible(!isUserDetailModalVisible);
  };

  const handleDeleteUser = (e) => {
    e.preventDefault();
    if (selectedUserId) {
      dispatch(requestDeleteUser(selectedUserId));
      setIsUserDeleteModalVisible(!isUserDeleteModalVisible);
      setSelectedUserId(null);
    }
  };

  const handleCloseUserDetailModal = () => {
    setSelectedUser(null);
    setIsUserDetailModalVisible(false);
  };

  const handleUserDeleteModal = () => {
    setIsUserDeleteModalVisible(!isUserDeleteModalVisible);
  };
  return (
    <div className="flex-grow overflow-x-auto">
      <div className="min-w-screen min-h-screen bg-gray-100 flex justify-center bg-gray-100 font-sans overflow-hidden">
        <div className="w-full ">
          <div className="bg-white shadow-md rounded overflow-auto mx-2 my-6">
            {user.isLoading ? (
              <Spinner />
            ) : (
              <Table columns={columns} data={userList} />
            )}
          </div>
        </div>
      </div>

      <Modal
        handleClose={handleCloseUserDetailModal}
        handleSave={handleSubmit(onSubmit)}
        isVisible={isUserDetailModalVisible}
        title="User Detail"
      >
        {isUserDetailModalVisible && (
          <div className="w-full mt-20">
            <div className="flex justify-between w-full mt-2">
              <div className="w-1/2">
                <InputForm
                  defaultValue={`${
                    selectedUser &&
                    typeof selectedUser.firstname !== "undefined"
                      ? selectedUser.firstname
                      : ""
                  }`}
                  label="Firstname"
                  placeholder=" "
                  error={errors.firstname ? true : false}
                  errorMessage="This field is required"
                  register={register("firstname", { required: true })}
                />
              </div>
              <div className="w-1/2">
                <InputForm
                  defaultValue={`${
                    selectedUser && typeof selectedUser.lastname !== "undefined"
                      ? selectedUser.lastname
                      : ""
                  }`}
                  label="Lastname"
                  placeholder=" "
                  error={errors.lastname ? true : false}
                  errorMessage="This field is required"
                  register={register("lastname", { required: true })}
                />
              </div>
            </div>
            <div className="flex justify-between w-full mt-10">
              <div className="w-full">
                <InputForm
                  type="date"
                  defaultValue={
                    selectedUser &&
                    selectedUser.birthdate &&
                    format(new Date(selectedUser.birthdate), INPUT_DATE_FORMAT)
                  }
                  label="Birthdate"
                  placeholder=" "
                  error={errors.birthdate ? true : false}
                  errorMessage="This field is required"
                  register={register("birthdate", { required: true })}
                />
              </div>
            </div>
            <div className="flex justify-between w-full mt-10">
              <div className="w-1/2">
                <SelectForm
                  options={OPTION_COUNTRIES}
                  error={errors.country ? true : false}
                  errorMessage="This field is required"
                  register={register("country", { required: true })}
                  label="Country"
                  placeholder=" "
                  // onChange={handleChangeCountry}
                />
              </div>
              <div className="w-1/2">
                <InputForm
                  defaultValue={`${
                    selectedUser &&
                    typeof selectedUser.current_phone_number !== "undefined"
                      ? selectedUser.current_phone_number
                      : ""
                  }`}
                  label="Phone Number"
                  placeholder=" "
                  error={errors.current_phone_number ? true : false}
                  errorMessage="This field is required"
                  register={register("current_phone_number", {
                    required: true,
                  })}
                />
              </div>
            </div>

            <div className="flex justify-between w-full mt-10">
              <div className="w-1/2">
                <InputForm
                  defaultValue={`${
                    selectedUser && typeof selectedUser.address !== "undefined"
                      ? selectedUser.address
                      : ""
                  }`}
                  label="Address"
                  placeholder=" "
                  error={errors.address ? true : false}
                  errorMessage="This field is required"
                  register={register("address", { required: true })}
                />
              </div>
              <div className="w-1/2">
                <InputForm
                  defaultValue={`${
                    selectedUser && typeof selectedUser.city !== "undefined"
                      ? selectedUser.city
                      : ""
                  }`}
                  label="City"
                  placeholder=" "
                  error={errors.city ? true : false}
                  errorMessage="This field is required"
                  register={register("city", { required: true })}
                />
              </div>
            </div>

            <div className="flex justify-between w-full mt-10">
              <div className="w-1/2">
                <InputForm
                  defaultValue={`${
                    selectedUser && typeof selectedUser.state !== "undefined"
                      ? selectedUser.state
                      : ""
                  }`}
                  label="State"
                  placeholder=" "
                  error={errors.state ? true : false}
                  errorMessage="This field is required"
                  register={register("state", { required: true })}
                />
              </div>
              <div className="w-1/2">
                <InputForm
                  defaultValue={`${
                    selectedUser && typeof selectedUser.state !== "undefined"
                      ? selectedUser.zip
                      : ""
                  }`}
                  label="Zipcode"
                  placeholder=" "
                  error={errors.zip ? true : false}
                  errorMessage="This field is required"
                  register={register("zip", { required: true })}
                />
              </div>
            </div>

            <div className="flex justify-between w-full mt-10">
              <div className="w-1/2">
                <InputForm
                  disabled={true}
                  defaultValue={selectedUser?.subscription?.plan?.nickname}
                  label="Subscription"
                  placeholder=" "
                />
              </div>
              <div className="w-1/2">
                <InputForm
                  disabled={true}
                  value={
                    selectedUser &&
                    selectedUser.subscription &&
                    selectedUser.subscription.status === "active"
                      ? "Active"
                      : "Inactive"
                  }
                  label="Subscription Status"
                  placeholder=" "
                />
              </div>
            </div>

            <div className="flex justify-between w-full mt-10">
              <div className="w-1/2">
                <InputForm
                  disabled={true}
                  defaultValue={
                    selectedUser && selectedUser.subscription
                      ? convertTimestamp(
                          selectedUser.subscription.current_period_start
                        )
                      : ""
                  }
                  label="Current Period Start"
                  placeholder=" "
                />
              </div>
              <div className="w-1/2">
                <InputForm
                  disabled={true}
                  defaultValue={
                    selectedUser && selectedUser.subscription
                      ? convertTimestamp(
                          selectedUser.subscription.current_period_end
                        )
                      : ""
                  }
                  label="Current Period End"
                  placeholder=" "
                />
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        handleClose={handleUserDeleteModal}
        handleSave={handleDeleteUser}
        isVisible={isUserDeleteModalVisible}
        title="User Detail"
        labelText="Delete"
      >
        <div className="w-full">Are you sure you want to delete this user?</div>
      </Modal>
    </div>
  );
};

export default Users;
