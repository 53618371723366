import { useEffect, useRef, useState } from "react";
import {  withRouter } from "react-router-dom";
import { useSelector } from "react-redux";


// LOCAL COMPONENTS
import HomeFooter from "./components/Footer";
import MainContent from "./components/Main";
import AppDownload from "./components/AppDownload";
import Contacts from "./components/Contacts";
import FAQ from "./components/FAQ";
import Features from "./components/Features";
import HowAppWorks from "./components/HowAppWorks";
import Subscriptions from "./components/Subscriptions";
import Testimonials from "./components/Testimonials";
import ModalQuestionairre from "./components/ModalQuestionairre";
import ModalHowToInstall from "./components/ModalHowToInstall";

// TYPES
import { DefaultProps } from "@/types/type";


const scrollOption = {
  behavior: "smooth",
  block: "center",
};

const getSubscriptionByScore = (score) => {
  if (score >= 4 && score <= 6) {
    return "Basic";
  } else if (score >= 7 && score <= 11) {
    return "Basic Plus";
  } else if (score >= 12 && score <= 15) {
    return "Premium";
  } else if (score >= 16) {
    return "Professional";
  }
  return;
};

const Home = (props: any) => {
  const home = useRef<HTMLDivElement>(null);
  const feature = useRef<HTMLDivElement>(null);
  const steps = useRef<HTMLDivElement>(null);
  const subscription = useRef<HTMLDivElement>(null);
  const contacts = useRef<HTMLDivElement>(null);
  const download = useRef<HTMLDivElement>(null);
  const downloads = useRef<HTMLDivElement>(null);
  const refs: { [key: string]: any } = {
    home,
    feature,
    steps,
    subscription,
    contacts,
    download,
    downloads
  };
  const { subscriptions } = useSelector((state: DefaultProps) => {
    return {
      subscriptions: state.subscriptions.list,
    };
  });

  const [isQuestionairreModalVisible, setIsQuestionairreModalVisible] =
    useState(false);
  const [isHowToInstallModalVisible, setIsHowToInstallModalVisible] =
    useState(false);
  const [howToInstallType, setHowToInstallType] = useState("");
  const [questionairreSteps, setQuestionairreSteps] = useState(1);
  const [recommendedSubscription, setRecommendedSubscription] = useState("");
  const [totalScore, setTotalScore] = useState(0);

  useEffect(() => {
    const currentSection =
      props.location.hash && props.location.hash.substring(1);
    if (
      currentSection &&
      refs[currentSection] &&
      refs[currentSection].current
    ) {
      refs[currentSection].current.scrollIntoView(scrollOption);
    }
  }, [props.location.pathname, props.location.hash]);

  const handleSelectSection = (type) => {
    if (refs[type] && refs[type].current) {
      refs[type].current.scrollIntoView(scrollOption);
    }
  };



  const handleResetQuestionnaireModal = () => {
    setRecommendedSubscription("");
    setQuestionairreSteps(1);
    setTotalScore(0);
    setIsQuestionairreModalVisible(false);
  };
  const handleQuestionnaireModalChange = () => {
    props.history.push("/signup");
    //setIsQuestionairreModalVisible(!isQuestionairreModalVisible);
  };

  const handleHowToInstallContentModal = (type = "") => {
    setIsHowToInstallModalVisible(!isHowToInstallModalVisible);
    setHowToInstallType(type);
  };

  const handleSubmitQuestionairre = function <T>(data: T) {
    const score = Object.keys(data).reduce((accum, item) => {
      return accum + parseInt(data[item]);
    }, 0);
    setTotalScore(score);
    setRecommendedSubscription(getSubscriptionByScore(score));
    setTimeout(() => {
      setQuestionairreSteps(questionairreSteps + 1);
    }, 500);
  };

  return (
    <>
      <div className="w-full">
        <div ref={home}>
          <MainContent
            handleQuestionnaireModalChange={handleQuestionnaireModalChange}
            handleSelectSection={handleSelectSection}
          />
        </div>
        <div ref={feature}>
          <Features />
        </div>
        <div ref={steps}>
          <HowAppWorks
            handleHowToInstallContentModal={handleHowToInstallContentModal}
          />
        </div>
        <Testimonials />
        <div ref={subscription}>
          <Subscriptions subscriptions={subscriptions} />
        </div>
        <FAQ />
        <div ref={downloads}>
          <div ref={download}>
            <AppDownload />
          </div>
        </div>
        <div ref={contacts}>
          <Contacts />
        </div>
      </div>
      <ModalQuestionairre
        questionairreSteps={questionairreSteps}
        setQuestionairreSteps={setQuestionairreSteps}
        recommendedSubscription={recommendedSubscription}
        handleSave={handleSubmitQuestionairre}
        handleClose={() => {
          handleResetQuestionnaireModal();
        }}
        isModalVisible={isQuestionairreModalVisible}
        totalScore={totalScore}
      />
      <ModalHowToInstall
        handleClose={handleHowToInstallContentModal}
        isVisible={isHowToInstallModalVisible}
        type={howToInstallType}
      />
      <HomeFooter handleSelectSection={handleSelectSection} />
    </>
  );
};

export default withRouter(Home);
