import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import contactRequest from "services/api/contact";

const Contacts = () => {
  const [t] = useTranslation('common');

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  });
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);

  useEffect(() => {
    return () => {
      setIsFeedbackSent(false);
    };
  }, []);

  const onSubmit = async (data) => {
    setIsFeedbackSent(false);
    try {
      const response = await contactRequest.send(data);
      if (response) {
        reset();
        setIsFeedbackSent(true);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  return (
    <div
      id="contacts"
      className="flex flex-wrapped flex-col items-center mt-12 bg-gray-700  md:px-12 h-1/4"
    >
      <div className="text-center lg:text-left  py-8 px-8 md:px-12">
        <h2 className="text-3xl font-semibold text-center text-white md:text-4xl">
          {t('contact.title')}
        </h2>
        {/* <p className="mt-6 text-white">
          {t('contact.description')}
        </p> */}
      </div>

      <div className="justify-evenly mt-2 lg:text-left px-2 py-8 md:px-12 w-full md:w-1/3  ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
        >
          {isFeedbackSent && (
            <div className="w-full px-3 py-2 mb-6 md:mb-0">
              <p className="text-green-400">Feedback has been sent!</p>
            </div>
          )}
          <div className="w-full px-3 mb-6 md:mb-0">
            <input
              className="appearance-none block w-full bg-gray-900 text-white border border-red rounded py-3 px-4 mb-3"
              id="grid-name"
              type="text"
              placeholder={t('contact.field_name')}
              {...register("name", { required: true })}
            />
          </div>
          <div className="w-full px-3 mb-6 md:mb-0">
            <input
              className="appearance-none block w-full bg-gray-900 text-white border border-red rounded py-3 px-4 mb-3"
              id="grid-name"
              type="email"
              placeholder={t('contact.field_email')}
              {...register("email", { required: true })}
            />
          </div>
          <div className="w-full px-3 mb-6 md:mb-0">
            <textarea
              rows={1}
              className="appearance-none block w-full bg-gray-900 text-white border border-red rounded py-3 px-4 mb-3"
              id="grid-subject"

              placeholder={t('contact.field_subject')}
              {...register("subject", { required: true })}
              style={{ resize: 'none' }}
            />
          </div>

          <div className="w-full px-3 mb-6 md:mb-0">
            <textarea
              className="appearance-none block w-full bg-gray-900 text-white border border-red rounded py-3 px-4 mb-3"
              id="grid-message"
              placeholder={t('contact.field_message')}
              {...register("message", { required: true })}
            />
          </div>

          <div className="flex items-end justify-center w-full">
            <input
              type="submit"
              className="px-10 py-2 bg-custom-purple text-lg text-center text-gray-200 text-xs  rounded-full hover:bg-custom-purple"
              value={t('contact.button_send')}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contacts;
