const OPTION_SUBSCRIPTION = [
  { value: "basic", label:"subscription.basic.title"  },
  // { value: "basic_plus", label: "Basic Plus" },
  { value: "premium", label: "subscription.premium.title" },
  // { value: "professional", label: "Professional" },
];

const OPTION_TIER_SUBSCRIPTION = {
  basic: [
    { value: "basic_tier_1", label: "subscription.basic.monthly_title" },
    { value: "basic_tier_2", label: "subscription.basic.yearly_title" },
  ],
  premium: [
    { value: "premium_tier_1", label: "subscription.premium.monthly_title" },
    { value: "premium_tier_2", label: "subscription.premium.yearly_title" },
  ],
};

const OPTION_TIERS = [
  { value: "basic_tier_1", label: "subscription.basic.monthly_title", parent: "basic" },
  { value: "basic_tier_2", label: "subscription.basic.yearly_title", parent: "basic" },

  { value: "premium_tier_1", label: "subscription.premium.monthly_title", parent: "premium" },
  { value: "premium_tier_2", label: "subscription.premium.yearly_title", parent: "premium" },
];

const OPTION_SUBSCRIPTION_DETAILS = {
  basic_tier_1: {
    price: 9.99,
    details: [
      "subscription.basic.monthly.feature_1",
      "subscription.basic.monthly.feature_2",
      `subscription.basic.monthly.feature_3`,
    ],
  },
  basic_tier_2: {
    price: 109.89,
    details: [
      "subscription.basic.yearly.feature_1",
      "subscription.basic.yearly.feature_2",
      `subscription.basic.yearly.feature_3`,
    ],
  },

  premium_tier_1: {
    price: 19.98,
    details: [
      "subscription.premium.monthly.feature_1",
      "subscription.premium.monthly.feature_2",
      `subscription.premium.monthly.feature_3`,
    ],
  },

  premium_tier_2: {
    price: 219.00,
    details: [
      "subscription.premium.yearly.feature_1",
      "subscription.premium.yearly.feature_2",
      `subscription.premium.yearly.feature_3`,
    ],
  },
};

export {
  OPTION_SUBSCRIPTION,
  OPTION_TIER_SUBSCRIPTION,
  OPTION_SUBSCRIPTION_DETAILS,
  OPTION_TIERS,
};
