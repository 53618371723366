const actionConstants = {
  REQUEST_USERS: "REQUEST_USERS",
  REQUEST_DELETE_USER: "REQUEST_DELETE_USER",
  SET_USER_LIST: "SET_USER_LIST",
};

const requestUsers = () => ({
  type: actionConstants.REQUEST_USERS,
});

const requestDeleteUser = (id: string) => ({
  type: actionConstants.REQUEST_DELETE_USER,
  id,
});

const setUserList = <T>(data: T) => ({
  type: actionConstants.SET_USER_LIST,
  data,
});

export { actionConstants, requestUsers, requestDeleteUser, setUserList };
