import { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// SHARED COMPONENT
import Modal from "components/Modal";

import CheckoutForm from "components/Stripe/CheckoutForm";
import SelectForm from "components/Forms/Select";

// SUBSCRIPTION COMPONENTS
import BillingHistory from "./SubscriptionComponents/BillingHistory";
import PaymentInformation from "./SubscriptionComponents/PaymentInformation";

// REDUX ACTIONS
import {
  requestCancelSubscriptions,
  requestCreateSubscriptions,
  requestUpdateSubscriptions,
} from "redux/subscription/action";

// CONSTANTS
import {
  OPTION_SUBSCRIPTION,
  OPTION_TIER_SUBSCRIPTION,
  OPTION_TIERS,
  OPTION_SUBSCRIPTION_DETAILS,
} from "constants/subscriptions";

type Props = {
  isSubscriptionUpdateSuccess: boolean;
  user: Nullable<Object>;
};

interface SubscriptionInput {
  subscription: string;
}

const Subscriptions = (props: Props) => {
  const { isSubscriptionUpdateSuccess, subscriptions, user } = props;
  const dispatch = useDispatch();
  const [t] = useTranslation('common');

  const currentSubscription = OPTION_TIERS.find(
    (item) => item.value === user?.subscription?.plan?.nickname
  );

  const translatedSubscriptionOption =  OPTION_SUBSCRIPTION.map(item => {
    return {
      ...item,
      label: t(item.label)
    }
  }) 


  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SubscriptionInput>({
    defaultValues: {
      subscription: currentSubscription?.parent,
      tier: currentSubscription?.value,
    },
  });
  const [isUpgradePlanModalVisible, setIsUpgradePlanModalVisible] =
    useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(
    currentSubscription?.parent || 'basic'
  );
  const translatedSubscriptionTierOption =  OPTION_TIER_SUBSCRIPTION[selectedSubscription] ? OPTION_TIER_SUBSCRIPTION[selectedSubscription].map(item => {
    return {
      ...item,
      label: t(item.label)
    }
  }) : [];
  const getCurrentSubs = function <T>(data: T): void {
    const currentSubs = subscriptions.find(
      (item) => item.name.toLowerCase().replace(" ", "_") === data.subscription
    );
    return (
      currentSubs &&
      currentSubs.plans.find((item) => item.nickname === data.tier)
    );
  };

  const handleUpdateSubscription = function <T>(data: T) {
    const currentSubs = getCurrentSubs(data);
    if (
      user.subscription &&
      user.subscription.items &&
      user.subscription.items.data
    ) {
      if (currentSubs) {
        const payload = {
          customer_id: user.stripe_id,
          subscription_item_id: user.subscription.items.data[0].id,
          price_id: currentSubs.id,
        };
        dispatch(requestUpdateSubscriptions(payload));
      }
    } else {
      if (currentSubs) {
        const payload = {
          customer_id: user.stripe_id,
          price_id: currentSubs.id,
        };
        dispatch(requestCreateSubscriptions(payload));
      }
    }
    setIsUpgradePlanModalVisible(!isUpgradePlanModalVisible);
  };

  const handleCancelSubscription = () => {
    const payload = {
      subscription_id: user.subscription.id,
    };
    dispatch(requestCancelSubscriptions(payload));
  };

  const handleUpgradePlanModal = () => {
    setIsUpgradePlanModalVisible(!isUpgradePlanModalVisible);
  };

  return (
    <div className="w-full">
      <div className="flex items-center mb-5 px-4">
        <div className="md:flex md:flex-items max-w-4xl bg-white w-full rounded-lg shadow-md pb-4 px-2">
          <div className="w-full md:w-9/12">
            <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 px-4 py-2 ">
              <h2 className="text-1xl font-semibold">
                {" "}
                {t('profile.subscription.plan_info')}{" "}
                {currentSubscription && `(${t(currentSubscription.label)})`}
              </h2>
            </div>
            {isSubscriptionUpdateSuccess && (
              <div className="w-full p-4 ">
                <p className="font-semibold text-green-600 md:text-1xl">
                  Subscription has been updated successfully!
                  {t('profile.subscription.subscription_update')}
                </p>
              </div>
            )}
            <div className=" space-y-1 px-4 py-2 ">
              {currentSubscription &&
                OPTION_SUBSCRIPTION_DETAILS[currentSubscription.value] &&
                OPTION_SUBSCRIPTION_DETAILS[
                  currentSubscription.value
                ].details.map((desc, index) => {
                  return (
                    <p key={index} className="text-gray-500">
                      {" "}
                      - {t(desc)}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="w-full md:w-3/12 py-2 ">
            <button
              onClick={handleUpgradePlanModal}
              className="w-full mx-auto cursor-pointer bg-white text-custom-purple border-2 border-custom-purple mb-4 font-bold text-center px-4 py-2 text-xs  font-light rounded-full hover:text-gray-200 hover:bg-custom-purple"
            >
              {t('profile.subscription.upgrade_plan').toUpperCase()}
            </button>
            {user && user.subscription && (
              <button
                onClick={handleCancelSubscription}
                className="w-full mx-auto cursor-pointer bg-white text-custom-purple border-2 border-custom-purple mb-4 font-bold text-center px-4 py-2 text-xs  font-light rounded-full hover:text-gray-200 hover:bg-custom-purple"
              >
                 {t('profile.subscription.cancel').toUpperCase()}
              </button>
            )}
          </div>
        </div>
      </div>
      <PaymentInformation user={user} />
      <BillingHistory billing_history={user.billing_history} />

      <Modal
        handleClose={handleUpgradePlanModal}
        handleSave={handleSubmit(handleUpdateSubscription)}
        isVisible={isUpgradePlanModalVisible}
        title={<div className="font-semibold">{t('profile.subscription.upgrade_plan').toUpperCase()}</div>}
        labelText={t('profile.edit_profile.update')}
      >
        <div className="w-full mt-20">
          <SelectForm
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              setSelectedSubscription(e.target.value);
              setValue("tier", OPTION_TIER_SUBSCRIPTION[value][0].value);
            }}
            error={errors.subscription ? true : false}
            errorMessage="This field is required"
            register={register("subscription", { required: true })}
            options={translatedSubscriptionOption}
            label={t('profile.subscription.title')}
            placeholder=" "
          />
          {selectedSubscription && (
            <div className="w-full mx-auto mt-8">
              <SelectForm
                //value={selectedTier}
                options={translatedSubscriptionTierOption}
                error={errors.tier ? true : false}
                errorMessage="This field is required"
                register={register("tier", { required: true })}
                label="Tier"
                placeholder=" "
                // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                //   const { value } = e.target;
                //   setSelectedTier(value);
                // }}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CheckoutForm(Subscriptions);
