import axiosInstance from "../index";

import { getToken } from "helpers/token";

const subscriptionRequest = {
  getSubscriptions: () => {
    return axiosInstance.get("subscription/list");
  },
  createSubscriptions: <T>(data: T, token: string = getToken()) => {
    return axiosInstance.post(
      "subscription/create",
      {
        ...data,
      },
      {
        headers: {
          token,
        },
      }
    );
  },
  updatePaymentMethod: <T>(data: T, token: string = getToken()) => {
    return axiosInstance.put(
      "subscription/card/update",
      {
        ...data,
      },
      {
        headers: {
          token,
        },
      }
    );
  },
  updateSubscriptions: <T>(data: T, token: string = getToken()) => {
    return axiosInstance.put(
      "subscription/update",
      {
        ...data,
      },
      {
        headers: {
          token,
        },
      }
    );
  },

  updatePaymentDetails: <T>(data: T, token: string = getToken()) => {
    return axiosInstance.put(
      "subscription/customer/update",
      {
        ...data,
      },
      {
        headers: {
          token,
        },
      }
    );
  },
  deleteSubscriptionRequest: <T>(data: T, token: string = getToken()) => {
    return axiosInstance.delete("subscription/delete", {
      data: {
        ...data,
      },
      headers: {
        token,
      },
    });
  },
};

export default subscriptionRequest;
