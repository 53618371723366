type Props = {
  checked: boolean;
  name: string;
  label: string;
  error?: boolean;
  errorMessage?: string;
  register?: Nullable<Object>;
  onChange?: () => void;
};

const RadioForm = ({
  checked = false,
  name,
  value,
  label,
  error,
  errorMessage,
  register,
  onChange,
}: Props) => {
  return (
    <div className="md-input-main">
      <div className="md-input-box">
        <label class="inline-flex items-center">
          <input
            {...(register || {})}
            type="radio"
            class="form-radio"
            value={value}
          />
          <span class="ml-2">{label}</span>
        </label>
      </div>
      {error && <span className="text-base text-red-700">{errorMessage}</span>}
    </div>
  );
};

export default RadioForm;
