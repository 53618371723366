import { useTranslation } from "react-i18next";

import DownloadApp from "@/assets/download.png";
import Google from "@/assets/google.png";
import Apple from "@/assets/apple.png";

const AppDownload = () => {
  const [t] = useTranslation('common');
  return (
    <div className="flex mt-20 bg-white md:px-12">
      <div className="flex lg:text-left sm:w-full md:px-12 lg:w-1/2 lg:px-12">
        <div className="px-8">
          <div>
            <h2 className="text-3xl font-semibold text-gray-800 md:text-3xl">
              {t('app_download.title')}
            </h2>
          </div>

          <div className="flex flex-wrap mt-2 lg:text-left">
            <div className="md:w-full py-2 sm:w-full  md:pr-6">
              <p className="text-gray-00">
                {t('app_download.description')}
              </p>

              <div className="flex  mt-2 lg:text-left">
                <img alt="google" style={{width:144,height:48.25}} src={Google} />

                <img alt="apple" style={{width:144,height:48.25}} src={Apple} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="justify-center hidden md:w-1/2 md:flex lg:w-1/2">
        <img alt="test" className="object-scale-down" style={{width:384,height:247.93}} src={DownloadApp} />
      </div>
    </div>
  );
};

export default AppDownload;
