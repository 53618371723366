import { useTranslation } from "react-i18next";

import { convertTimestamp, setCurrencyFormat } from "helpers/index";

// CONSTANTS
import { OPTION_TIERS } from "constants/subscriptions";

const BillingHistory = (props: any) => {
  const [t] = useTranslation('common');

  const { billing_history = [] } = props;

  return (
    <div className="flex items-center mb-5 px-4">
      <div className="md:flex md:flex-items max-w-4xl bg-white w-full rounded-lg shadow-md pb-4">
        <div className="w-full md:w-full">
          <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 px-4 py-2 ">
            <h2 className="text-1xl font-semibold">{t('profile.subscription.billing_history')}</h2>
          </div>
          <div className="px-4 py-2 ">
            <div className="overflow-auto">
              <table className="w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t('profile.subscription.date')}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t('profile.subscription.type')}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                     {t('profile.subscription.transaction_id')}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t('profile.subscription.amount')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {billing_history.map((billing, index) => {
                    const currentBillingType = OPTION_TIERS.find(
                      (item) =>
                        billing?.lines?.data[0]?.plan?.nickname === item.value
                    );
                    return (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {convertTimestamp(billing.created)}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {currentBillingType ? t(currentBillingType.label) : ''}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {billing.id}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {setCurrencyFormat(billing.amount_paid / 100)}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingHistory;
