import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import InputForm from "components/Forms/Input";

type Props = {
  isUpdateComplete: boolean,
  isLoading: boolean;
  user: Nullable<Object>;
};

const EditPassword = (props: Props) => {
  const [t] = useTranslation('common');

  const { error, isUpdateComplete, user, save } = props;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const newPassword = useRef({});
  const [updateError, setUpdateError] = useState<Object | Nullable>(null);
  newPassword.current = watch("new_password", "");

  useEffect(() => {
    if (error) {
      setUpdateError(error);
    }
  }, [error]);

  const onSubmit = (data: any) => {
    setUpdateError(null);
    const payload = {
      auth_id: user.auth_id,
      email: user.email,
      ...data,
    };

    save(payload);
  };
  return (
    <div className="w-full md:w-3/4">
      <div className="flex items-center px-4">
        <div className="max-w-4xl bg-white w-full rounded-lg shadow-md">
          <div className="p-4 flex w-full my-3 text-red-700">
            {updateError?.message}
          </div>
          <div className="p-4 border-green-700 border-b">
            <h2 className="text-2xl font-semibold">{t('profile.change_password.title')}</h2>
          </div>

          {isUpdateComplete && (
            <div className="w-full p-4 ">
              <p className="font-semibold text-green-600 md:text-1xl">
                User password has been updated successfully!
              </p>
            </div>
          )}
          <div className="p-4">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="w-full mx-auto mt-6">
                <InputForm
                  type="password"
                  error={errors.old_password ? true : false}
                  errorMessage="This field is required"
                  register={register("old_password", { required: true })}
                  label={t('profile.change_password.old_password')}
                  placeholder=" "
                />
              </div>
              <div className="w-full mx-auto mt-6">
                <InputForm
                  type="password"
                  error={errors.new_password ? true : false}
                  errorMessage={
                    errors.new_password && errors.new_password.message
                  }
                  register={register("new_password", {
                    required: t('signup.password_error'),
                    minLength: {
                      value: 8,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                  label={t('profile.change_password.password')}
                  placeholder=" "
                />
              </div>
              <div className="w-full mx-auto mt-6">
                <InputForm
                  type="password"
                  error={errors.confirm_new_password ? true : false}
                  errorMessage={
                    errors.confirm_new_password &&
                    errors.confirm_new_password.message
                  }
                  register={register("confirm_new_password", {
                    validate: (value) =>
                      value === newPassword.current ||
                      t('signup.password_not_match')
                  })}
                  label={t('profile.change_password.confirm_password')}
                  placeholder=" "
                />
              </div>

              <div className="mt-2 w-full md:w-3/12 ">
                {/* <button
                  disabled={props.isLoading}
                  className="break-word mx-auto w-full cursor-pointer py-2 bg-green-600 text-sm text-gray-200 font-light rounded-full hover:bg-green-700"
                >
                  {props.isLoading ? "Please Wait..." : "UPDATE"}
                </button> */}

                <button
                  disabled={props.isLoading}
                  className="mx-auto px-8 cursor-pointer bg-white text-custom-purple border-2 border-custom-purple mb-4 font-bold text-center px-4 py-2 text-xs  font-light rounded-full hover:text-gray-200 hover:bg-custom-purple"
                >
                  {props.isLoading ? "Please Wait..." : t('profile.edit_profile.update')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPassword;
