import { useState } from "react";
import { Route } from "react-router-dom";

import Body from "./components/Body";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";

import Users from "./components/Users";

import ProtectedRoute from "components/Route/ProtectedRoute";

type Props = {
  isAuthenticated: boolean;
};

const pages = [
  // {
  //   name: "Dashboard",
  //   iconClass: "fas fa-home",
  //   isProtected: true,
  //   path: "/admin",
  //   component: () => <Home />,
  // },
  {
    name: "Users",
    iconClass: "fas fa-user",
    isProtected: true,
    path: "/admin",
    component: () => <Users />,
  },
];

const Admin = (props: Props) => {
  const { isAuthenticated } = props;
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleSidebarToggle = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <Route
      render={({ location }) => {
        return (
          <div className="w-full">
            <Navbar
              handleSidebarToggle={handleSidebarToggle}
              pages={pages}
              location={location}
            />
            <Body>
              <div className="flex">
                <Sidebar
                  isSidebarVisible={isSidebarVisible}
                  pages={pages}
                  location={location}
                />
                {pages.map((page, index) => {
                  return (
                    <ProtectedRoute
                      exact
                      isAdmin
                      path={page.path}
                      isAuthenticated={isAuthenticated || false}
                      key={index}
                      component={page.component}
                    />
                  );
                })}
              </div>
            </Body>
          </div>
        );
      }}
    />
  );
};

export default Admin;
