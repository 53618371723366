import React from "react";
import { useTranslation } from "react-i18next";

import "../styles.css";

type Props = {
  handleSelectSection(type: string): void;
};
/* w-1/2 sm:w-4/12 md:w-3/12 */
const HomeFooter = (props: Props) => {
  const [t] = useTranslation('common');

  return (
    <footer className="w-full p-12 bg-gray-800">
      <div className="flex items-center justify-center py-2 px-8 md:px-12">
        <div className="flex flex-col md:block -mx-2">
          <span
            onClick={() => {
              props.handleSelectSection("home");
            }}
            className="footer-menu"
          >
            {t('menu.home')}
          </span>
          <span
            onClick={() => {
              props.handleSelectSection("feature");
            }}
            className="footer-menu"
          >
            {t('menu.features')}
          </span>
          <span
            onClick={() => {
              props.handleSelectSection("steps");
            }}
            className="footer-menu"
          >
            {t('menu.how_to_install')}
          </span>
          <span
            onClick={() => {
              props.handleSelectSection("subscription");
            }}
            className="footer-menu"
          >
            {t('menu.subscription')}
          </span>
          <span
            onClick={() => {
              props.handleSelectSection("contacts");
            }}
            className="footer-menu"
          >
            {t('menu.contact_us')}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <p className="text-gray-100 text-size-xs">
          {t('footer_1')}
        </p>
      </div>
    </footer>
  );
};

export default HomeFooter;
