import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Cover from "./components/Cover";
import EditPassword from "./components/EditPassword";
import EditProfile from "./components/EditProfile";
import Overview from "./components/Overview";
import Subscriptions from "./components/Subscriptions";

import { AuthProps } from "types/interface";

import {
  requestUpdateUser,
  requestUpdateUserPassword,
} from "redux/auth/action";

const Profile = (props: any) => {
  const [t] = useTranslation('common');

  const dispatch = useDispatch();
  const { auth, subscriptions } = useSelector((state: AuthProps) => {
    return {
      auth: state.auth,
      subscriptions: state.subscriptions,
    };
  });
  const [selectedMenu, setSelectedMenu] = useState("overview");

  const handleSelectMenu = (type: string) => {
    setSelectedMenu(type);
  };

  const handleUpdateUserDetails = <T extends {}>(data: T):void => {
    dispatch(requestUpdateUser(data));
  };

  const handleUpdateUserPassword = <T extends {}>(data: T):void => {
    dispatch(requestUpdateUserPassword(data));
  };

  return (
    <div className="w-full h-auto bg-green-50 py-20">
      <Cover user={auth.user} />
      <div className="md:flex md:flex-items md:mt-20 mx-auto w-11/12 md:w-4/5 ">
        <div className="h-full px-4 py-2 w-full md:w-1/4">
          <div className="bg-white">
            <div
              onClick={() => {
                handleSelectMenu("overview");
              }}
              className="break-words cursor-pointer px-4 py-2 pb-4 hover:bg-gray-100 flex"
            >
              <p className="cursor-pointer text-lg font-medium text-gray-800 leading-none">
                {t('profile.overview.title')}
              </p>
            </div>
            <div
              onClick={() => {
                handleSelectMenu("edit_profile");
              }}
              className="break-words cursor-pointer px-4 py-2 pb-4 hover:bg-gray-100 flex"
            >
              <p className="text-lg font-medium text-gray-800 leading-none">
              {t('profile.edit_profile.title')}
              </p>
            </div>
            <div
              onClick={() => {
                handleSelectMenu("change_password");
              }}
              className="break-words cursor-pointer px-4 py-2 pb-4 hover:bg-gray-100 flex"
            >
              <p className="text-lg font-medium text-gray-800 leading-none">
              {t('profile.change_password.title')}
              </p>
            </div>
            <div
              onClick={() => {
                handleSelectMenu("subscription");
              }}
              className="break-words cursor-pointer px-4 py-2 pb-4 hover:bg-gray-100 flex"
            >
              <p className="text-lg font-medium text-gray-800 leading-none">
              {t('profile.subscription.title')}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full mt-2 md:w-3/4">
          {auth.user &&
            auth.user.subscription &&
            auth.user.subscription.status === "canceled" && (
              <div className="w-full md:w-3/4 pb-6">
                <div className="flex items-center px-4">
                  <div className="max-w-4xl bg-white w-full rounded-lg shadow-md">
                    <div
                      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                      role="alert"
                    >
                      <span className="font-bold block">
                        {t('subscription_expired')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {selectedMenu === "overview" && (
            <Overview handleSelectMenu={handleSelectMenu} user={auth.user} />
          )}
          {selectedMenu === "edit_profile" && (
            <EditProfile
              isLoading={auth.isLoading}
              save={handleUpdateUserDetails}
              isUpdateComplete={auth.isUpdateSuccess}
              user={auth.user}
            />
          )}
          {selectedMenu === "change_password" && (
            <EditPassword
              error={auth.error}
              isLoading={auth.isLoading}
              save={handleUpdateUserPassword}
              isUpdateComplete={auth.isChangePasswordSuccess}
              user={auth.user}
            />
          )}
          {selectedMenu === "subscription" && (
            <Subscriptions
              isSubscriptionUpdateSuccess={
                subscriptions.isSubscriptionUpdateSuccess
              }
              subscriptions={subscriptions.list}
              user={auth.user}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
