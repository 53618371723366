import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import subscriptionSaga from './subscription/saga';
import userSaga from './users/saga';

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    subscriptionSaga(),
    userSaga()
  ]);
}