const OPTION_COUNTRIES = [
  { label: "United States", dial_prefix: "+1", value: "US" },
  { label: "Afghanistan", dial_prefix: "+93", value: "AF" },
  { label: "Albania", dial_prefix: "+355", value: "AL" },
  { label: "Algeria", dial_prefix: "+213", value: "DZ" },
  { label: "AmericanSamoa", dial_prefix: "+1 684", value: "AS" },
  { label: "Andorra", dial_prefix: "+376", value: "AD" },
  { label: "Angola", dial_prefix: "+244", value: "AO" },
  { label: "Anguilla", dial_prefix: "+1 264", value: "AI" },
  { label: "Antigua and Barbuda", dial_prefix: "+1268", value: "AG" },
  { label: "Argentina", dial_prefix: "+54", value: "AR" },
  { label: "Armenia", dial_prefix: "+374", value: "AM" },
  { label: "Aruba", dial_prefix: "+297", value: "AW" },
  { label: "Australia", dial_prefix: "+61", value: "AU" },
  { label: "Austria", dial_prefix: "+43", value: "AT" },
  { label: "Azerbaijan", dial_prefix: "+994", value: "AZ" },
  { label: "Bahamas", dial_prefix: "+1 242", value: "BS" },
  { label: "Bahrain", dial_prefix: "+973", value: "BH" },
  { label: "Bangladesh", dial_prefix: "+880", value: "BD" },
  { label: "Barbados", dial_prefix: "+1 246", value: "BB" },
  { label: "Belarus", dial_prefix: "+375", value: "BY" },
  { label: "Belgium", dial_prefix: "+32", value: "BE" },
  { label: "Belize", dial_prefix: "+501", value: "BZ" },
  { label: "Benin", dial_prefix: "+229", value: "BJ" },
  { label: "Bermuda", dial_prefix: "+1 441", value: "BM" },
  { label: "Bhutan", dial_prefix: "+975", value: "BT" },
  { label: "Bosnia and Herzegovina", dial_prefix: "+387", value: "BA" },
  { label: "Botswana", dial_prefix: "+267", value: "BW" },
  { label: "Brazil", dial_prefix: "+55", value: "BR" },
  { label: "British Indian Ocean Territory", dial_prefix: "+246", value: "IO" },
  { label: "Bulgaria", dial_prefix: "+359", value: "BG" },
  { label: "Burkina Faso", dial_prefix: "+226", value: "BF" },
  { label: "Burundi", dial_prefix: "+257", value: "BI" },
  { label: "Cambodia", dial_prefix: "+855", value: "KH" },
  { label: "Cameroon", dial_prefix: "+237", value: "CM" },
  { label: "Canada", dial_prefix: "+1", value: "CA" },
  { label: "Cape Verde", dial_prefix: "+238", value: "CV" },
  { label: "Cayman Islands", dial_prefix: "+ 345", value: "KY" },
  { label: "Central African Republic", dial_prefix: "+236", value: "CF" },
  { label: "Chad", dial_prefix: "+235", value: "TD" },
  { label: "Chile", dial_prefix: "+56", value: "CL" },
  { label: "China", dial_prefix: "+86", value: "CN" },
  { label: "Christmas Island", dial_prefix: "+61", value: "CX" },
  { label: "Colombia", dial_prefix: "+57", value: "CO" },
  { label: "Comoros", dial_prefix: "+269", value: "KM" },
  { label: "Congo", dial_prefix: "+242", value: "CG" },
  { label: "Cook Islands", dial_prefix: "+682", value: "CK" },
  { label: "Costa Rica", dial_prefix: "+506", value: "CR" },
  { label: "Croatia", dial_prefix: "+385", value: "HR" },
  { label: "Cuba", dial_prefix: "+53", value: "CU" },
  { label: "Cyprus", dial_prefix: "+537", value: "CY" },
  { label: "Czech Republic", dial_prefix: "+420", value: "CZ" },
  { label: "Denmark", dial_prefix: "+45", value: "DK" },
  { label: "Djibouti", dial_prefix: "+253", value: "DJ" },
  { label: "Dominica", dial_prefix: "+1 767", value: "DM" },
  { label: "Dominican Republic", dial_prefix: "+1 849", value: "DO" },
  { label: "Ecuador", dial_prefix: "+593", value: "EC" },
  { label: "Egypt", dial_prefix: "+20", value: "EG" },
  { label: "El Salvador", dial_prefix: "+503", value: "SV" },
  { label: "Equatorial Guinea", dial_prefix: "+240", value: "GQ" },
  { label: "Eritrea", dial_prefix: "+291", value: "ER" },
  { label: "Estonia", dial_prefix: "+372", value: "EE" },
  { label: "Ethiopia", dial_prefix: "+251", value: "ET" },
  { label: "Faroe Islands", dial_prefix: "+298", value: "FO" },
  { label: "Fiji", dial_prefix: "+679", value: "FJ" },
  { label: "Finland", dial_prefix: "+358", value: "FI" },
  { label: "France", dial_prefix: "+33", value: "FR" },
  { label: "French Guiana", dial_prefix: "+594", value: "GF" },
  { label: "French Polynesia", dial_prefix: "+689", value: "PF" },
  { label: "Gabon", dial_prefix: "+241", value: "GA" },
  { label: "Gambia", dial_prefix: "+220", value: "GM" },
  { label: "Georgia", dial_prefix: "+995", value: "GE" },
  { label: "Germany", dial_prefix: "+49", value: "DE" },
  { label: "Ghana", dial_prefix: "+233", value: "GH" },
  { label: "Gibraltar", dial_prefix: "+350", value: "GI" },
  { label: "Greece", dial_prefix: "+30", value: "GR" },
  { label: "Greenland", dial_prefix: "+299", value: "GL" },
  { label: "Grenada", dial_prefix: "+1 473", value: "GD" },
  { label: "Guadeloupe", dial_prefix: "+590", value: "GP" },
  { label: "Guam", dial_prefix: "+1 671", value: "GU" },
  { label: "Guatemala", dial_prefix: "+502", value: "GT" },
  { label: "Guinea", dial_prefix: "+224", value: "GN" },
  { label: "Guinea-Bissau", dial_prefix: "+245", value: "GW" },
  { label: "Guyana", dial_prefix: "+595", value: "GY" },
  { label: "Haiti", dial_prefix: "+509", value: "HT" },
  { label: "Honduras", dial_prefix: "+504", value: "HN" },
  { label: "Hungary", dial_prefix: "+36", value: "HU" },
  { label: "Iceland", dial_prefix: "+354", value: "IS" },
  { label: "India", dial_prefix: "+91", value: "IN" },
  { label: "Indonesia", dial_prefix: "+62", value: "ID" },
  { label: "Iraq", dial_prefix: "+964", value: "IQ" },
  { label: "Ireland", dial_prefix: "+353", value: "IE" },
  { label: "Israel", dial_prefix: "+972", value: "IL" },
  { label: "Italy", dial_prefix: "+39", value: "IT" },
  { label: "Jamaica", dial_prefix: "+1 876", value: "JM" },
  { label: "Japan", dial_prefix: "+81", value: "JP" },
  { label: "Jordan", dial_prefix: "+962", value: "JO" },
  { label: "Kazakhstan", dial_prefix: "+7 7", value: "KZ" },
  { label: "Kenya", dial_prefix: "+254", value: "KE" },
  { label: "Kiribati", dial_prefix: "+686", value: "KI" },
  { label: "Kuwait", dial_prefix: "+965", value: "KW" },
  { label: "Kyrgyzstan", dial_prefix: "+996", value: "KG" },
  { label: "Latvia", dial_prefix: "+371", value: "LV" },
  { label: "Lebanon", dial_prefix: "+961", value: "LB" },
  { label: "Lesotho", dial_prefix: "+266", value: "LS" },
  { label: "Liberia", dial_prefix: "+231", value: "LR" },
  { label: "Liechtenstein", dial_prefix: "+423", value: "LI" },
  { label: "Lithuania", dial_prefix: "+370", value: "LT" },
  { label: "Luxembourg", dial_prefix: "+352", value: "LU" },
  { label: "Madagascar", dial_prefix: "+261", value: "MG" },
  { label: "Malawi", dial_prefix: "+265", value: "MW" },
  { label: "Malaysia", dial_prefix: "+60", value: "MY" },
  { label: "Maldives", dial_prefix: "+960", value: "MV" },
  { label: "Mali", dial_prefix: "+223", value: "ML" },
  { label: "Malta", dial_prefix: "+356", value: "MT" },
  { label: "Marshall Islands", dial_prefix: "+692", value: "MH" },
  { label: "Martinique", dial_prefix: "+596", value: "MQ" },
  { label: "Mauritania", dial_prefix: "+222", value: "MR" },
  { label: "Mauritius", dial_prefix: "+230", value: "MU" },
  { label: "Mayotte", dial_prefix: "+262", value: "YT" },
  { label: "Mexico", dial_prefix: "+52", value: "MX" },
  { label: "Monaco", dial_prefix: "+377", value: "MC" },
  { label: "Mongolia", dial_prefix: "+976", value: "MN" },
  { label: "Montenegro", dial_prefix: "+382", value: "ME" },
  { label: "Montserrat", dial_prefix: "+1664", value: "MS" },
  { label: "Morocco", dial_prefix: "+212", value: "MA" },
  { label: "Myanmar", dial_prefix: "+95", value: "MM" },
  { label: "Namibia", dial_prefix: "+264", value: "NA" },
  { label: "Nauru", dial_prefix: "+674", value: "NR" },
  { label: "Nepal", dial_prefix: "+977", value: "NP" },
  { label: "Netherlands", dial_prefix: "+31", value: "NL" },
  { label: "Netherlands Antilles", dial_prefix: "+599", value: "AN" },
  { label: "New Caledonia", dial_prefix: "+687", value: "NC" },
  { label: "New Zealand", dial_prefix: "+64", value: "NZ" },
  { label: "Nicaragua", dial_prefix: "+505", value: "NI" },
  { label: "Niger", dial_prefix: "+227", value: "NE" },
  { label: "Nigeria", dial_prefix: "+234", value: "NG" },
  { label: "Niue", dial_prefix: "+683", value: "NU" },
  { label: "Norfolk Island", dial_prefix: "+672", value: "NF" },
  { label: "Northern Mariana Islands", dial_prefix: "+1 670", value: "MP" },
  { label: "Norway", dial_prefix: "+47", value: "NO" },
  { label: "Oman", dial_prefix: "+968", value: "OM" },
  { label: "Pakistan", dial_prefix: "+92", value: "PK" },
  { label: "Palau", dial_prefix: "+680", value: "PW" },
  { label: "Panama", dial_prefix: "+507", value: "PA" },
  { label: "Papua New Guinea", dial_prefix: "+675", value: "PG" },
  { label: "Paraguay", dial_prefix: "+595", value: "PY" },
  { label: "Peru", dial_prefix: "+51", value: "PE" },
  { label: "Philippines", dial_prefix: "+63", value: "PH" },
  { label: "Poland", dial_prefix: "+48", value: "PL" },
  { label: "Portugal", dial_prefix: "+351", value: "PT" },
  { label: "Puerto Rico", dial_prefix: "+1 939", value: "PR" },
  { label: "Qatar", dial_prefix: "+974", value: "QA" },
  { label: "Romania", dial_prefix: "+40", value: "RO" },
  { label: "Rwanda", dial_prefix: "+250", value: "RW" },
  { label: "Samoa", dial_prefix: "+685", value: "WS" },
  { label: "San Marino", dial_prefix: "+378", value: "SM" },
  { label: "Saudi Arabia", dial_prefix: "+966", value: "SA" },
  { label: "Senegal", dial_prefix: "+221", value: "SN" },
  { label: "Serbia", dial_prefix: "+381", value: "RS" },
  { label: "Seychelles", dial_prefix: "+248", value: "SC" },
  { label: "Sierra Leone", dial_prefix: "+232", value: "SL" },
  { label: "Singapore", dial_prefix: "+65", value: "SG" },
  { label: "Slovakia", dial_prefix: "+421", value: "SK" },
  { label: "Slovenia", dial_prefix: "+386", value: "SI" },
  { label: "Solomon Islands", dial_prefix: "+677", value: "SB" },
  { label: "South Africa", dial_prefix: "+27", value: "ZA" },
  {
    label: "South Georgia and the South Sandwich Islands",
    dial_prefix: "+500",
    value: "GS",
  },
  { label: "Spain", dial_prefix: "+34", value: "ES" },
  { label: "Sri Lanka", dial_prefix: "+94", value: "LK" },
  { label: "Sudan", dial_prefix: "+249", value: "SD" },
  { label: "Suriname", dial_prefix: "+597", value: "SR" },
  { label: "Swaziland", dial_prefix: "+268", value: "SZ" },
  { label: "Sweden", dial_prefix: "+46", value: "SE" },
  { label: "Switzerland", dial_prefix: "+41", value: "CH" },
  { label: "Tajikistan", dial_prefix: "+992", value: "TJ" },
  { label: "Thailand", dial_prefix: "+66", value: "TH" },
  { label: "Togo", dial_prefix: "+228", value: "TG" },
  { label: "Tokelau", dial_prefix: "+690", value: "TK" },
  { label: "Tonga", dial_prefix: "+676", value: "TO" },
  { label: "Trinidad and Tobago", dial_prefix: "+1 868", value: "TT" },
  { label: "Tunisia", dial_prefix: "+216", value: "TN" },
  { label: "Turkey", dial_prefix: "+90", value: "TR" },
  { label: "Turkmenistan", dial_prefix: "+993", value: "TM" },
  { label: "Turks and Caicos Islands", dial_prefix: "+1 649", value: "TC" },
  { label: "Tuvalu", dial_prefix: "+688", value: "TV" },
  { label: "Uganda", dial_prefix: "+256", value: "UG" },
  { label: "Ukraine", dial_prefix: "+380", value: "UA" },
  { label: "United Arab Emirates", dial_prefix: "+971", value: "AE" },
  { label: "United Kingdom", dial_prefix: "+44", value: "GB" },
  //{ label: "United States", dial_prefix: "+1", value: "US" },
  { label: "Uruguay", dial_prefix: "+598", value: "UY" },
  { label: "Uzbekistan", dial_prefix: "+998", value: "UZ" },
  { label: "Vanuatu", dial_prefix: "+678", value: "VU" },
  { label: "Wallis and Futuna", dial_prefix: "+681", value: "WF" },
  { label: "Yemen", dial_prefix: "+967", value: "YE" },
  { label: "Zambia", dial_prefix: "+260", value: "ZM" },
  { label: "Zimbabwe", dial_prefix: "+263", value: "ZW" },
  { label: "land Islands", dial_prefix: "", value: "AX" },
  { label: "Antarctica", dial_prefix: null, value: "AQ" },
  {
    label: "Bolivia, Plurinational State of",
    dial_prefix: "+591",
    value: "BO",
  },
  { label: "Brunei Darussalam", dial_prefix: "+673", value: "BN" },
  { label: "Cocos (Keeling) Islands", dial_prefix: "+61", value: "CC" },
  {
    label: "Congo, The Democratic Republic of the",
    dial_prefix: "+243",
    value: "CD",
  },
  { label: "Cote d'Ivoire", dial_prefix: "+225", value: "CI" },
  { label: "Falkland Islands (Malvinas)", dial_prefix: "+500", value: "FK" },
  { label: "Guernsey", dial_prefix: "+44", value: "GG" },
  { label: "Holy See (Vatican City State)", dial_prefix: "+379", value: "VA" },
  { label: "Hong Kong", dial_prefix: "+852", value: "HK" },
  { label: "Iran, Islamic Republic of", dial_prefix: "+98", value: "IR" },
  { label: "Isle of Man", dial_prefix: "+44", value: "IM" },
  { label: "Jersey", dial_prefix: "+44", value: "JE" },
  {
    label: "Korea, Democratic People's Republic of",
    dial_prefix: "+850",
    value: "KP",
  },
  { label: "Korea, Republic of", dial_prefix: "+82", value: "KR" },
  {
    label: "Lao People's Democratic Republic",
    dial_prefix: "+856",
    value: "LA",
  },
  { label: "Libyan Arab Jamahiriya", dial_prefix: "+218", value: "LY" },
  { label: "Macao", dial_prefix: "+853", value: "MO" },
  {
    label: "Macedonia, The Former Yugoslav Republic of",
    dial_prefix: "+389",
    value: "MK",
  },
  {
    label: "Micronesia, Federated States of",
    dial_prefix: "+691",
    value: "FM",
  },
  { label: "Moldova, Republic of", dial_prefix: "+373", value: "MD" },
  { label: "Mozambique", dial_prefix: "+258", value: "MZ" },
  {
    label: "Palestinian Territory, Occupied",
    dial_prefix: "+970",
    value: "PS",
  },
  { label: "Pitcairn", dial_prefix: "+872", value: "PN" },
  { label: "Réunion", dial_prefix: "+262", value: "RE" },
  { label: "Russia", dial_prefix: "+7", value: "RU" },
  { label: "Saint Barthélemy", dial_prefix: "+590", value: "BL" },
  {
    label: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_prefix: "+290",
    value: "SH",
  },
  { label: "Saint Kitts and Nevis", dial_prefix: "+1 869", value: "KN" },
  { label: "Saint Lucia", dial_prefix: "+1 758", value: "LC" },
  { label: "Saint Martin", dial_prefix: "+590", value: "MF" },
  { label: "Saint Pierre and Miquelon", dial_prefix: "+508", value: "PM" },
  {
    label: "Saint Vincent and the Grenadines",
    dial_prefix: "+1 784",
    value: "VC",
  },
  { label: "Sao Tome and Principe", dial_prefix: "+239", value: "ST" },
  { label: "Somalia", dial_prefix: "+252", value: "SO" },
  { label: "Svalbard and Jan Mayen", dial_prefix: "+47", value: "SJ" },
  { label: "Syrian Arab Republic", dial_prefix: "+963", value: "SY" },
  { label: "Taiwan, Province of China", dial_prefix: "+886", value: "TW" },
  { label: "Tanzania, United Republic of", dial_prefix: "+255", value: "TZ" },
  { label: "Timor-Leste", dial_prefix: "+670", value: "TL" },
  {
    label: "Venezuela, Bolivarian Republic of",
    dial_prefix: "+58",
    value: "VE",
  },
  { label: "Viet Nam", dial_prefix: "+84", value: "VN" },
  { label: "Virgin Islands, British", dial_prefix: "+1 284", value: "VG" },
  { label: "Virgin Islands, U.S.", dial_prefix: "+1 340", value: "VI" },
];

export default OPTION_COUNTRIES;
