import axiosInstance from "../index";

import { getToken } from "helpers/token";

const authRequest = {
  getCurrentUser: (token: string = getToken()) => {
    return axiosInstance.get("auth/me", {
      headers: {
        token,
      },
    });
  },
  signUp: <T>(data: T) => {
    return axiosInstance.post("auth/signup", {
      ...data,
    });
  },
  signIn: <T>(data: T) => {
    return axiosInstance.post("auth/signin", {
      ...data,
    });
  },
  adminSignIn: <T>(data: T) => {
    return axiosInstance.post("auth/admin/signin", {
      ...data,
    });
  },
  updateUser: <T>(data: T, token: string = getToken()) => {
    return axiosInstance.put(
      "auth/update",
      {
        ...data,
      },
      {
        headers: {
          token,
        },
      }
    );
  },
  updateUserPassword: <T>(data: T, token: string = getToken()) => {
    return axiosInstance.put(
      "auth/changepassword",
      {
        ...data,
      },
      {
        headers: {
          token,
        },
      }
    );
  },
  resetPassword: <T>(data: T) => {
    return axiosInstance.post("auth/resetpassword", {
      ...data,
    });
  },
};

export default authRequest;
