import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51IjpqJHEFy0ahOuC4pWGoB86t052jQZL6JTALRxQPMzJFzsEzslDZa0vojXRmY0mVoXr1P6zAaoxarz9XJaePbdn00WxGd4a2H"
);

const CheckoutForm = (Component) => (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Component {...props} />
    </Elements>
  );
};

export default CheckoutForm;
