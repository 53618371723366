import React from "react";
import { Switch } from "react-router-dom";

import Admin from "pages/Admin";
import AdminSignIn from "pages/Admin/Auth";
import NonProtectedRoute from "components/Route/NonProtectedRoute";

import { DefaultProps } from "types/type";

const AdminRoutes = (props: DefaultProps) => {
  const { isAuthenticated } = props;
  return (
    <Switch>
      <NonProtectedRoute
        exact
        isAdmin
        path="/admin/signin"
        isAuthenticated={isAuthenticated || false}
      >
        <AdminSignIn {...props} />
      </NonProtectedRoute>
      <Admin isAuthenticated={isAuthenticated} />
    </Switch>
  );
};

export default AdminRoutes;
