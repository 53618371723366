import { actionConstants } from './action';

const initialState = {
	isLoading: false,
	isSignupSuccess: false,
	isUpdateSuccess: false,
	isChangePasswordSuccess: false,
	isResetPasswordSuccess: false,
	error: {},
	user: null
};

const auth = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.REQUEST_SIGN_IN:
			return {
				...state,
				isLoading: true,
				error: {}
			};
		case actionConstants.REQUEST_SIGN_UP:
			return {
				...state,
				isLoading: true,
				isSignupSuccess: false,
				error: {}
			};
		case actionConstants.REQUEST_UPDATE_USER:
			return {
				...state,
				isLoading: true,
				isUpdateSuccess: false,
				error: {}
			};
		case actionConstants.REQUEST_CURRENT_USER_UPDATE_COMPLETE:
			return {
				...state,
				isUpdateSuccess: true,
				error: {}
			};
		case actionConstants.REQUEST_CURRENT_USER_PASSWORD:
			return {
				...state,
				error: {},
				isLoading: true
			};

		case actionConstants.REQUEST_SIGN_UP_COMPLETE:
			return {
				...state,
				isLoading: false,
				isSignupSuccess: true,
				error: {}
			};
		case actionConstants.REQUEST_SIGN_IN_FAILED || actionConstants.REQUEST_SIGN_UP_FAILED:
			return {
				...state,
				isLoading: false,
				isSignupSuccess: false,
				error: {
					...action.data
				}
			};

		case actionConstants.REQUEST_CURRENT_USER:
			return {
				...state,
				error: {},
				isLoading: true
			};

		case actionConstants.REQUEST_LOGOUT:
			return {
				...state,
				isLoading: false,
				user: null,
				error: {}
			};
		case actionConstants.SET_CURRENT_USER:
			return {
				...state,
				error: {},
				isLoading: false,
				user: {
					...action.data
				}
			};
		case actionConstants.REQUEST_UPDATE_FAILED:
			return {
				...state,
				isLoading: false,
				isSignupSuccess: false,
				error: {
					...action.error
				}
			};
		case actionConstants.REQUEST_CHANGE_PASSWORD_COMPLETE:
			return {
				...state,
				isLoading: false,
				isChangePasswordSuccess: true
			};
		case actionConstants.RESET_STATUS_MESSAGE:
			return {
				...state,
				isSignupSuccess: false,
				isUpdateSuccess: false,
				isResetPasswordSuccess: false,
				isChangePasswordSuccess: false
			}
		default:
			return state;
	}
};

export default auth;
