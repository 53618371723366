import { useState } from "react";
import { Link } from "react-router-dom";
// import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";


import HeaderLogo from "@/assets/header_logo.png";

// SERVICES
import authRequest from "services/api/auth";


interface Inputs {
    email: string;
}

const ForgotPassword = (props: any) => {
    const [t] = useTranslation('common');
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Inputs>({ mode: "onSubmit" });
    const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSubmit = async (data: Inputs) => {
        setIsPasswordResetSuccess(false);
        setIsLoading(true);
        setErrorMessage('');
        try {
            const response = await authRequest.resetPassword({
                email: data.email
            });
            if (response && response.data) {
                setIsPasswordResetSuccess(true);
                reset();
            }
            setIsLoading(false);

        } catch (err) {
            if (err && err.request && err.request.responseText) {
                const error = JSON.parse(err.request.responseText);
                setErrorMessage(error.message);
            }
            reset();
            setIsLoading(false);
        }

    };

    return (
        <div className="bg-auth p-4 flex flex-wrap justify-center w-full h-screen bg-repeat">
            <div className="mx-auto my-auto sm:px-4 md:px-10 py-5 w-full md:w-3/4 lg:w-2/5 lg:max-w-2/5">
                <div className="bg-white mt-24 border-2 border-gray-100 px-5 py-16 rounded-md tracking-wide shadow-lg">
                    <div id="header" className="flex">
                        <div id="body" className="flex flex-col w-full px-6">
                            <div className="w-full pb-8">
                                <img
                                    alt="logo"
                                    src={HeaderLogo}
                                    className="w-36 md:w-44 mx-auto h-auto"
                                />
                            </div>
                            <div className="w-full">
                                <div className="flex w-full my-3 text-red-700">
                                    {/* {signInError?.message} */}
                                </div>
                                <h4 className="font-semibold text-gray-800 md:text-2xl">
                                    {t('forgot_password.reset_password')}
                                </h4>
                            </div>
                            {isPasswordResetSuccess && (
                                <div className="w-full">
                                    <p className="font-semibold text-green-600 md:text-1xl">
                                        {t('forgot_password.reset_password_success')}
                                    </p>
                                </div>
                            )}
                            {errorMessage !== '' && (
                                <div className="w-full">
                                    <p className="font-semibold text-red-600 md:text-1xl">
                                        {errorMessage}
                                    </p>
                                </div>
                            )}
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                autoComplete="forgot-password"
                            >
                                <div className="w-full mx-auto mt-8">
                                    <div className="md-input-main">
                                        <div className="md-input-box">
                                            <input
                                                id="email"
                                                type="email"
                                                className="md-input"
                                                placeholder=" "
                                                {...register("email", {
                                                    required: true,
                                                    pattern: {
                                                        value: /\S+@\S+.\S+/,
                                                        message: 'Invalid Email'
                                                    },
                                                })}
                                            />
                                            <label htmlFor="email" className="md-label">
                                                {t('signin.email')}
                                            </label>
                                            <div className="md-input-underline" />
                                        </div>
                                        {errors.email && (
                                            <span className="text-base text-red-700">
                                                {/* This field is required */}
                                                {errors.email.message}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="flex w-full my-3">
                                    <button
                                        disabled={isLoading}
                                        className="mx-auto w-full cursor-pointer px-8 py-2 bg-custom-purple text-sm text-gray-200 font-light rounded-full"
                                    >
                                        {isLoading ? t('profile.please_wait') : t('forgot_password.send')}

                                    </button>
                                </div>

                                <div className="flex w-full my-3">
                                    <span className="mx-auto text-sm font-light">
                                        <Link
                                            className="cursor-pointer text-custom-purple"
                                            to="/signin"
                                        >
                                            {t('forgot_password.back_to_signin')}
                                        </Link>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
