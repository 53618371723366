// SHARED COMPONENT
import Check from "components/Icons/Check";
import Modal from "components/Modal";

const ModalHowToInstall = (props) => {
  const { handleClose, isVisible, type } = props;
  return (
    <Modal
      viewMode={true}
      handleClose={handleClose}
      isVisible={isVisible}
      title="Upgrade Plan"
    >
      {type === "signup" && (
        <div>
          <div className="flex flex-items bg-pink-50">
            <div className="w-4/5 font-semibold px-4 py-4 text-lg">
              How to install the app
            </div>

            <div className="flex-grow text-right pt-4 pr-6  text-lg">
              <span onClick={handleClose} className="cursor-pointer">
                X
              </span>
            </div>
          </div>
          <div className="px-12">
            <ul className="p-4 ">
              <li className="flex flex-items my-4">
                <span className="pt-1">
                  <Check />
                </span>
                <span className="px-2">
                  Once downloaded, you can click the signup and you’ll be
                  redirected to the landing page of the website where you’ll be
                  asked to answer the subscription level questionnaire.
                </span>
              </li>
              <li className="flex flex-items my-4">
                <span className="pt-1">
                  <Check />
                </span>{" "}
                <span className="px-2">
                  Based on your tallied score, you can subscribe to the tier
                  package that fits your needs. Select your preferred tier level
                  and give the app permission to access your contacts and your
                  preferred method for syncing contacts (manual or automatic).
                  Enjoy your free 7-day trial period, afterwich your selected
                  tier will become activated.
                </span>
              </li>
              <li className="flex flex-items my-4">
                <span className="pt-1">
                  <Check />
                </span>{" "}
                <span className="px-2">
                  After successful subscription, you’ll receive a username and a
                  password sent to your email that you can use to login to the
                  App.
                </span>
              </li>

              <li className="flex flex-items my-4">
                <span className="pt-1">
                  <Check />
                </span>{" "}
                <span className="px-2">
                  In the Website, you can login to see your profile, track and
                  manage your subscription.
                </span>
              </li>
              <li className="flex flex-items my-4">
                <span className="pt-1">
                  <Check />
                </span>{" "}
                <span className="px-2">
                  You are now ready to use and enjoy the App.
                </span>
              </li>
            </ul>
          </div>
        </div>
      )}

      {type === "manage" && (
        <div>
          <div className="flex flex-items bg-pink-50">
            <div className="w-4/5 font-semibold px-4 py-4 text-lg">
              App Features
            </div>

            <div className="flex-grow text-right pt-4 pr-6 text-lg">
              <span onClick={handleClose} className="cursor-pointer">
                X
              </span>
            </div>
          </div>
          <div className="px-12">
            <ul className="p-4 ">
              <li className="my-4">
                <div className="flex flex-items font-semibold">
                  <span className="pt-1">
                    <Check />
                  </span>{" "}
                  <span className="px-2">Sync Phone Contacts</span>
                </div>
                <div className="pl-7">
                  After the initial installation, syncing occurs automatically
                  every time the app is opened on your phone. Syncing will only
                  occur with those contacts to whom access is granted. When new
                  contacts are added to your phone and you open the app and
                  refresh, a notification will pop-up seeking permission to
                  upload/sync that contact with the app. If a contact is deleted
                  in the app, it will not be deleted on your phone. If you wish
                  to re-sync the number after its been deleted simply open the
                  app on the phone and reauthorize the sync.
                </div>
              </li>
              <li className="my-4">
                <div className="flex flex-items font-semibold">
                  <span className="pt-1">
                    <Check />
                  </span>{" "}
                  <span className="px-2">Reverse Population</span>
                </div>
                <div className="pl-7">
                  User can longer worry about transferring their contact from
                  the app to their phone. This feature gives the user an ability
                  to download their contacts from the app to their new phone.
                </div>
              </li>
              <li className="my-4">
                <div className="flex flex-items font-semibold">
                  <span className="pt-1">
                    <Check />
                  </span>{" "}
                  <span className="px-2">Manage Phone Contacts</span>
                </div>
                <div className="pl-7">
                  This feature allows you to create categories, add important
                  contacts, and delete contacts. Contacts can be housed in
                  multiple categories, (i.e., work and family) or keep as
                  individual contact. Please note that if a push notification is
                  sent to two different groups and there is cross over between
                  the two groups (i.e. work and family), that contact will
                  receive 2 notifications. Updating contact information can
                  happen either in the app or on the phone, the new information
                  will be sync between the two locations once the app is opened
                  on the phone. Contact detail, adding personalized information
                  to each contact will help you to manage important dates like
                  birthdays or other major life events.
                </div>
              </li>

              <li className="my-4">
                <div className="flex flex-items font-semibold">
                  <span className="pt-1">
                    <Check />
                  </span>{" "}
                  <span className="px-2">Send SMS</span>
                </div>
                <div className="pl-7">
                  This feature allows for the sending of SMS to one, multiple
                  individuals, or to groups. SMS messages will be sent to the
                  individual contact and will not create one large text with
                  multiple numbers. This privacy feature helps protect the
                  identity and personal information of the members in the group.
                  Responses to your SMS will be found on your phone affiliated
                  with the app. The app has not been designed to receive SMS,
                  only to send.
                </div>
              </li>

              <li className="my-4">
                <div className="flex flex-items font-semibold">
                  <span className="pt-1">
                    <Check />
                  </span>{" "}
                  <span className="px-2">Send Email</span>
                </div>
                <div className="pl-7">
                  The email feature allows the users to send an email to one, multiple individuals or groups, with or without attachments. Responses to emails will be found in the inbox of the email address affiliated with the app. The app has been designed to send and not receive emails.
                </div>
              </li>

              <li className="my-4">
                <div className="flex flex-items font-semibold">
                  <span className="pt-1">
                    <Check />
                  </span>{" "}
                  <span className="px-2">Push Notification Settings</span>
                </div>
                <div className="pl-7">
                  The settings tab will allow the user to send automatic and automated PUSH notifications, change passwords, and synching capabilities.
                  <ol>


                    <li><b>New Number</b> - This feature is used to notify contacts via timed Push notifications that their information has changed - phone number, mailing address, email, facebook etc., Timed Push Notifications  can be sent via SMS, Voice, or Email. Set the number of reminders and the frequency in which you want the reminders to be sent.</li>
                    <li><b>Push Notifications - Voice Message Notification</b> - This feature allows the user to send voice messages to landline or mobile phone based individuals and businesses (doctors office, school, countryclub) that their contact information - phone number or mailing address has changed.  Set the number of reminders and the frequency in which you want the reminders to be sent.</li>
                    <li><b>Push Notifications - Email Notification</b> - This feature allows for automatic reminders that your email address has changed.  Set the number of reminders and the frequency in which you want the reminders to be sent. Recipient responses will be received to your email address on file.</li>
                    <li><b>Push Notifications - Birthday Message Notification</b> - This feature will send customized automated messages to your contacts that are celebrating their birthday today. Create your message and the app will send out this customized message to birthday recipients on their birthdate.</li>
                  </ol>
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ModalHowToInstall;
