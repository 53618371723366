type Props = {
  option: [];
  type?: string;
  label: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  register?: Nullable<Object>;
  onChange?: () => void;
};

const SelectForm = ({
  options = [],
  defaultValue = "",
  label,
  placeholder,
  error,
  errorMessage,
  register,
  onChange,
}: Props) => {
  return (
    <div className="md-input-main">
      <div className="md-input-box">
        <select
          {...(register || {})}
          placeholder={placeholder}
          className="md-input text-base"
          onChange={onChange}
        >
          {options.map((item) => (
            <option
              selected={defaultValue === item.value}
              key={item.value}
              value={item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
        <label htmlFor="name" className="md-label">
          {label}
        </label>
        <div className="md-input-underline" />
      </div>
      {error && <span className="text-base text-red-700">{errorMessage}</span>}
    </div>
  );
};

export default SelectForm;
