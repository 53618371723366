import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

// SHARED COMPONENT
import Modal from "components/Modal";
import RadioForm from "components/Forms/Radio";

const ModalQuestionairre = (props) => {
  const { register, handleSubmit } = useForm();
  const {
    questionairreSteps,
    setQuestionairreSteps,
    handleSave,
    handleClose,
    isModalVisible,
    recommendedSubscription,
    totalScore,
  } = props;

  return (
    <Modal
      viewMode={
        questionairreSteps === 1 || questionairreSteps === 3 ? true : false
      }
      handleSave={handleSubmit(handleSave)}
      handleClose={handleClose}
      isVisible={isModalVisible}
      title={<div className="text-xl font-semibold">Subscription Test</div>}
      labelText="Submit"
    >
      <div className="w-full p-4">
        {questionairreSteps === 1 && (
          <div className="w-full p-4 my-2">
            <div className="flex flex-items">
              <div className="w-11/12 font-semibold px-2 py-4 text-lg">
                Do you want to take the test first, to know what subscription
                fit for your needs?
              </div>

              <div className="flex-grow text-center pt-4 text-lg">
                <span onClick={handleClose} className="cursor-pointer">
                  X
                </span>
              </div>
            </div>
            <button
              onClick={() => {
                setQuestionairreSteps(questionairreSteps + 1);
              }}
              type="button"
              className="w-full inline-flex my-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-custom-purple text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Yes, I want to take the test first.
            </button>
            <Link
              to="/signup"
              className="w-full inline-flex my-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-custom-purple text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:my-2 sm:text-sm"
            >
              Proceed me to signup anyway
            </Link>
          </div>
        )}
        {questionairreSteps === 2 && (
          <>
            <div className="w-full my-2">
              <div className="w-full text-xl font-semibold">
                1. Which range of contacts describe you best?
              </div>
              <RadioForm
                register={register("question_1", { required: true })}
                value={1}
                label="Less than 100"
              />
              <RadioForm
                register={register("question_1", { required: true })}
                value={2}
                label="Between 200 - 500"
              />
              <RadioForm
                register={register("question_1", { required: true })}
                value={3}
                label="Between 501 - 1000"
              />
              <RadioForm
                register={register("question_1", { required: true })}
                value={4}
                label="Between 1001 - 2000"
              />
              <RadioForm
                register={register("question_1", { required: true })}
                value={5}
                label="Over 2001"
              />
            </div>

            <div className="w-full my-2">
              <div className="w-full text-xl font-semibold">
                2. How often do you change your number?
              </div>
              <RadioForm
                register={register("question_2", { required: true })}
                value={1}
                label="Rarely"
              />
              <RadioForm
                register={register("question_2", { required: true })}
                value={2}
                label="Every 10+ years"
              />
              <RadioForm
                register={register("question_2", { required: true })}
                value={3}
                label="Every 3 to 5 years"
              />
              <RadioForm
                register={register("question_2", { required: true })}
                value={4}
                label="Every 2 years"
              />
              <RadioForm
                register={register("question_2", { required: true })}
                value={5}
                label="Once a year"
              />
            </div>

            <div className="w-full my-2">
              <div className="w-full text-xl font-semibold">
                3. How often do you engage with your contacts on changes in your
                life?
              </div>
              <RadioForm
                register={register("question_3", { required: true })}
                value={1}
                label="Rarely"
              />
              <RadioForm
                register={register("question_3", { required: true })}
                value={2}
                label="Sometimes"
              />
              <RadioForm
                register={register("question_3", { required: true })}
                value={3}
                label="Often"
              />
              <RadioForm
                register={register("question_3", { required: true })}
                value={4}
                label="Daily"
              />
            </div>

            <div className="w-full my-2">
              <div className="w-full text-xl font-semibold">
                4. How often do you send birthday or anniversary greetings to
                friends/family?
              </div>
              <RadioForm
                register={register("question_4", { required: true })}
                value={1}
                label="Never"
              />
              <RadioForm
                register={register("question_4", { required: true })}
                value={2}
                label="Sometimes"
              />
              <RadioForm
                register={register("question_4", { required: true })}
                value={3}
                label="Often"
              />
              <RadioForm
                register={register("question_4", { required: true })}
                value={4}
                label="Daily"
              />
            </div>
          </>
        )}
        {questionairreSteps === 3 && (
          <div className="w-full m-h-48 m my-2">
            <div className="w-full text-xl">
              Your Score is: <b>{totalScore}</b>
            </div>
            <div className="w-full text-xl">
              The recommended subscription for you is{" "}
              <b>{recommendedSubscription}</b>
            </div>
            <div className="w-full text-xl">
              <Link
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 my-4 bg-custom-purple text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500  sm:w-auto sm:text-sm"
                to={`/signup${
                  recommendedSubscription !== ""
                    ? `?subscription=${recommendedSubscription
                        .replace(/ /g, "_")
                        .toLowerCase()}`
                    : ""
                }`}
              >
                Click here to proceed to signup
              </Link>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalQuestionairre;
