import Adjust from "@adjustcom/adjust-web-sdk";



const initializeAnalytics =  () => {
     Adjust.initSdk({
        appToken: 'lhenm2ki3i0w',
        environment: 'production',
        logLevel: 'verbose',
        //defaultTracker:'5b8775n',
        //logOutput: '#output',
        // attributionCallback: function (e, attribution) {
        //     // e: internal event name, can be ignored
        //     // attribution: details about the changed attribution
        //     console.log('attributionCallback e', e)
        //     console.log('attributionCallback attribution', attribution)
        // }
    });
}


const trackEvent = (params = {}) => {
    Adjust.trackEvent({
        eventToken: '1oxdr3',
        environment:'production',
        ...params
    });
}
export {
    initializeAnalytics,
    trackEvent
}