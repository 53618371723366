import { all, call, put, takeLatest } from "redux-saga/effects";

// API
import userRequest from "@/services/api/user";

// REDUX ACTIONS
import { actionConstants, setUserList } from "./action";

function* getUsersRequest(action) {
  try {
    const response = yield call(userRequest.getUsers);
    if (response.data.users) {
      yield put(setUserList(response.data.users));
    }
  } catch (error) {
    console.log("getUsersRequest error", error);
  }
}

function* deleteUserRequest(action) {
  try {
    const response = yield call(userRequest.deleteUser, action.id);
    if (response.data.users) {
      yield put(setUserList(response.data.users));
    }
  } catch (error) {
    console.log("getUsersRequest error", error);
  }
}

export default function* userSaga() {
  yield all([
    takeLatest(actionConstants.REQUEST_USERS, getUsersRequest),
    takeLatest(actionConstants.REQUEST_DELETE_USER, deleteUserRequest),
  ]);
}
