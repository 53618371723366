import { useState } from "react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

// SHARED COMPONENTS
import InputForm from "components/Forms/Input";
import SelectForm from "components/Forms/Select";

// CONSTANTS
import OPTION_COUNTRIES from "constants/countries";

interface Inputs {
  email: string;
  password: string;
  confirm_password: string;
  firstname: string;
  lastname: string;
  birthdate: string;
  phone_number: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
}

type Props = {
  isLoading: boolean;
  isUpdateComplete: boolean;
  user: Nullable<Object>;
  save: <T,>(data: T) => void;
};

const DATE_FORMAT = "yyyy-MM-dd";

const EdtProfile = (props: Props) => {
  const [t] = useTranslation('common');

  const { isUpdateComplete, user, save } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [selectedDialCode, setSelectedDialCode] = useState("+1");

  const onSubmit = (data: any) => {
    const payload = {
      default_email: user.email,
      ...user,
      ...data,
    };
    save(payload);
  };

  const handleChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const currentCountry = OPTION_COUNTRIES.find(
      (country) => country.value === value
    );
    setSelectedDialCode(currentCountry.dial_prefix);
  };

  return (
    <div className="w-full md:w-3/4">
      <div className="flex items-center px-4">
        <div className="max-w-4xl bg-white w-full rounded-lg shadow-md">
          <div className="p-4 border-green-700 border-b">
            <h2 className="text-2xl font-semibold">{t('profile.edit_profile.title')}</h2>
          </div>

          {isUpdateComplete && (
            <div className="w-full p-4 ">
              <p className="font-semibold text-green-600 md:text-1xl">
                User details has been updated successfully!
              </p>
            </div>
          )}
          <div className="p-4">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="md:flex md:justify-between w-full mt-6">
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.firstname}
                    error={errors.firstname ? true : false}
                    errorMessage="This field is required"
                    register={register("firstname", { required: true })}
                    label={t('profile.edit_profile.firstname')}
                    placeholder=" "
                  />
                </div>
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.lastname}
                    error={errors.firstname ? true : false}
                    errorMessage="This field is required"
                    register={register("lastname", { required: true })}
                    label={t('profile.edit_profile.lastname')}
                    placeholder=" "
                  />
                </div>
              </div>
              <div className="md:flex md:justify-between w-full mt-6">
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.email}
                    error={errors.email ? true : false}
                    errorMessage="This field is required"
                    register={register("email", { required: true })}
                    label={t('profile.edit_profile.email')}
                    placeholder=" "
                  />
                </div>
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    type="date"
                    defaultValue={
                      user &&
                      user.birthdate &&
                      format(new Date(user.birthdate), DATE_FORMAT)
                    }
                    error={errors.birthdate ? true : false}
                    errorMessage="This field is required"
                    register={register("birthdate")}
                    label={t('profile.edit_profile.birthdate')}
                    placeholder=" "
                  />
                </div>
              </div>
              <div className="md:flex md:justify-between w-full mt-6">
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <SelectForm
                    defaultValue={user && user.country}
                    options={OPTION_COUNTRIES}
                    error={errors.country ? true : false}
                    errorMessage="This field is required"
                    register={register("country", { required: true })}
                    label={t('profile.edit_profile.country')}
                    placeholder=" "
                    onChange={handleChangeCountry}
                  />
                </div>
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.current_phone_number}
                    error={errors.current_phone_number ? true : false}
                    errorMessage="This field is required"
                    register={register("current_phone_number")}
                    label={t('profile.edit_profile.phone_number')}
                    placeholder=" "
                  />
                </div>
              </div>
              <div className="md:flex md:justify-between w-full mt-6">
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.address}
                    error={errors.address ? true : false}
                    errorMessage="This field is required"
                    register={register("address")}
                    label={t('profile.edit_profile.address')}
                    placeholder=" "
                  />
                </div>
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.city}
                    error={errors.city ? true : false}
                    errorMessage="This field is required"
                    register={register("city")}
                    label={t('profile.edit_profile.city')}
                    placeholder=" "
                  />
                </div>
              </div>
              <div className="md:flex md:justify-between w-full mt-6">
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.state}
                    error={errors.state ? true : false}
                    errorMessage="This field is required"
                    register={register("state")}
                    label={t('profile.edit_profile.state')}
                    placeholder=" "
                  />
                </div>
                <div className="w-full mt-6 md:mt-0 md:w-1/2">
                  <InputForm
                    defaultValue={user && user.zip}
                    error={errors.zip ? true : false}
                    errorMessage="This field is required"
                    register={register("zip")}
                    label={t('profile.edit_profile.zip_code')}
                    placeholder=" "
                  />
                </div>
              </div>

              <div className="mt-2 w-full md:w-3/12 ">
                {/* <button
                  disabled={props.isLoading}
                  className="break-word mx-auto w-full cursor-pointer py-2 bg-green-600 text-sm text-gray-200 font-light rounded-full hover:bg-green-700"
                >
                  {props.isLoading ? "Please Wait..." : "UPDATE"}
                </button> */}

                <button
                  disabled={props.isLoading}
                  className="mx-auto px-8 cursor-pointer bg-white text-custom-purple border-2 border-custom-purple mb-4 font-bold text-center px-4 py-2 text-xs  font-light rounded-full hover:text-gray-200 hover:bg-custom-purple"
                >
                  {props.isLoading ? "PLEASE WAIT..." : t('profile.edit_profile.update')}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdtProfile;
