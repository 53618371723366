import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { requestLogout } from "redux/auth/action";

type Props = {
  isSidebarVisible: boolean;
  location: Nullable<Object>;
  pages: Nullable<Array>;
};

const Sidebar = (props: Props) => {
  const { isSidebarVisible, location, pages } = props;
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(requestLogout());
  };

  return (
    <nav
      className={
        "sidebar-container border-r overflow-y-auto lg:w-1/5 absolute sm:relative bg-cover bg-no-repeat bg-top bg-white z-0 " +
        (isSidebarVisible ? "hidden sm:flex" : "flex sm:hidden")
      }
    >
      <ul className="list-reset flex-1 mx-2 z-10">
        {pages.map((page, index) => (
          <LinkItem page={page} key={index} location={location} />
        ))}

        <li key="logout">
          <div className="flex items-center justify-start p-4 my-2 rounded no-underline">
            <i className={" px-1"} />
            <span onClick={handleLogout} className="cursor-pointer px-1">
              Logout
            </span>
          </div>
        </li>
      </ul>
    </nav>
  );
};

const LinkItem = ({ page, location } = props) => (
  <li>
    <Link
      to={page.path}
      className={`flex items-center justify-start p-4 my-2 rounded no-underline ${
        location.pathname === page.path
          ? " bg-blue-light bg-custom-purple text-white"
          : " text-grey-darker hover:bg-grey-lighter"
      }`}
    >
      <i className={page.iconClass + " px-1"} />
      <span className="px-1">{page.name}</span>
    </Link>
  </li>
);

export default Sidebar;
