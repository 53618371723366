import { Link } from 'react-router-dom';
import Phone from "@/assets/phone.png";
import { useTranslation } from "react-i18next";
// bg-gradient-to-b from-green-50

type Props = {
  handleSelectSection(type: string): void;
  handleQuestionnaireModalChange(): void;
};
// sm:h-auto md:h-screen
const MainContent = (props: Props) => {
  const [t] = useTranslation('common');

  return (
    <>
      <div className="flex flex-col bg-custom-green bg-cover bg-no-repeat py-8 sm:h-auto md:min-h-full md:px-12">
        <div className="flex mt-24">
          <div className="flex items-center text-left md:text-center sm:p-12 lg:text-left px-8 md:px-12 lg:w-1/2">
            <div>
              <div className="text-2xl  font-sans font-semibold text-gray-800 md:text-3xl">
                {t('banner.title1')}
              </div>
              <p className="text-gray-700 italic md:text-lg">
                {t('banner.title2')}
              </p>

              <ul className="text-lg font-semibold pl-0 md:pl-8">
                <li> {t('banner.list1')}</li>
                <li> {t('banner.list2')}</li>
                <li> {t('banner.list3')}</li>
                <li> {t('banner.list4')}</li>
              </ul>

              <div className="flex justify-center lg:justify-start mt-6">
                <Link
                  to="/#download"
                  // onClick={() => {
                  //   props.handleQuestionnaireModalChange();
                  // }}
                  className="cursor-pointer px-8 py-2 bg-custom-purple text-lg text-gray-200 text-xs font-light rounded-full"
                >
                  {t('download')}
                </Link>
              </div>
            </div>
          </div>
          <div className="items-center hidden lg:text-left px-8 md:px-12 md:flex lg:w-1/2">
            <div>
              <img alt="test" className="h-auto w-1/2/" src={Phone} />
            </div>
          </div>
        </div>
        <div className="flex items-center h-1/4 md:px-12 mt-12">
          <div
            onClick={() => {
              props.handleSelectSection("feature");
            }}
            className="cursor-pointer mx-auto p-2 rounded-full bg-green-100 h-auto"
          >
            <svg
              className="w-10 h-auto "
              fill="none"
              stroke="#B751E1"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainContent;
