import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { requestSignIn } from "redux/auth/action";

import HeaderLogo from "assets/header_logo.png";

import { AuthProps } from "types/type";

interface Inputs {
  email: string;
  password: string;
}

const AdminSignIn = (props: any) => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state: AuthProps) => {
    return {
      auth: state.auth,
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit = (data: any) => {
    dispatch(requestSignIn(data, true));
  };

  return (
    <div className="bg-auth flex flex-wrap justify-center w-full h-screen">
      <div className="mx-auto my-auto sm:px-4 md:px-10 py-5 w-full lg:w-1/3">
        <div className="bg-white border-2 border-gray-100 px-5 py-16 rounded-md tracking-wide shadow-lg">
          <div id="header" className="flex">
            <div id="body" className="flex flex-col w-full px-6">
              <div className="w-full pb-16">
                <img
                  alt="logo"
                  src={HeaderLogo}
                  className="w-32 mx-auto h-auto"
                />
              </div>
              <div className="w-full">
                <h4 className="font-semibold text-gray-800 md:text-2xl">
                  Sign In
                </h4>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="w-full mx-auto mt-8">
                  <div className="md-input-main">
                    <div className="md-input-box">
                      <input
                        id="email"
                        type="email"
                        className="md-input"
                        placeholder=" "
                        {...register("email", { required: true })}
                      />
                      <label htmlFor="email" className="md-label">
                        Email Address
                      </label>
                      <div className="md-input-underline" />
                    </div>
                    {errors.email && (
                      <span className="text-base text-red-700">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="w-full mx-auto mt-8">
                  <div className="md-input-main">
                    <div className="md-input-box">
                      <input
                        id="password"
                        type="password"
                        className="md-input"
                        placeholder=" "
                        {...register("password", { required: true })}
                      />

                      <label htmlFor="password" className="md-label">
                        Password
                      </label>
                      <div className="md-input-underline" />
                    </div>
                    {errors.password && (
                      <span className="text-base text-red-700">
                        This field is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex w-full my-3">
                  <button
                    type="submit"
                    disabled={auth.isLoading}
                    className="mx-auto w-full cursor-pointer px-8 py-2 bg-custom-purple text-sm text-gray-200 font-light rounded-full"
                  >
                    {auth.isLoading ? "PLEASE WAIT..." : "SIGN IN"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSignIn;
