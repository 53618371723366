type Props = {
  type?: string;
  label: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  register?: Nullable<Object>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const InputForm = ({
  type = "text",
  disabled = false,
  value = null,
  defaultValue,
  label,
  placeholder,
  error,
  errorMessage,
  register,
  onChange,
}: Props) => {
  let customProps = {};

  if (value) {
    customProps = {
      value,
    };
  }

  return (
    <div className="md-input-main">
      <div className="md-input-box">
        <input
          {...customProps}
          {...(register || {})}
          autoComplete="new-password"
          onChange={onChange}
          disabled={disabled}
          defaultValue={defaultValue}
          type={type}
          className="md-input text-base"
          placeholder={placeholder}
        />
        <label htmlFor="name" className="md-label">
          {label}
        </label>
        <div className="md-input-underline" />
      </div>
      {error && <span className="text-base text-red-700">{errorMessage}</span>}
    </div>
  );
};

export default InputForm;
