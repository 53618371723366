import { actionConstants } from "./action";

const initialState = {
  isLoading: false,
  error: {},
  list: [],
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case actionConstants.REQUEST_USERS:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case actionConstants.REQUEST_DELETE_USER:
      return {
        ...state,
        isLoading: true,
        error: {},
      };
    case actionConstants.SET_USER_LIST:
      return {
        ...state,
        list: action.data,
        error: {},
        isLoading: false,
      };
    default:
      return state;
  }
};

export default user;
