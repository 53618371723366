import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";



type Props = {
  subscriptions: [];
};

const Subscriptions = (props: Props) => {
  const [t] = useTranslation('common');

  const SUBSCRIPTIONS = {
    basic: {
      score: "(Score 4-6)",
      tier1: () => (
        <div>
          <div className="font-semibold text-base md:text-3xl"> $9.99</div>
          <div>
            <ul className="list-disc">
              <li className="p-1 md:p-3 text-gray-400">{t('subscription.basic.monthly.feature_1')}</li>
              <li className="p-1 md:p-3 text-gray-400">{t('subscription.basic.monthly.feature_2')}</li>
  
              <li className="p-1 md:p-3 text-gray-400">
              {t('subscription.basic.monthly.feature_3')}
              </li>
            </ul>
          </div>
        </div>
      ),
      tier2: () => {
        return (
          <div>
            <div className="font-semibold text-base md:text-3xl"> $109.89</div>
            <div>
              <ul className="list-disc">
                <li className="p-1 md:p-3 text-gray-400">{t('subscription.basic.yearly.feature_1')}</li>
                <li className="p-1 md:p-3 text-gray-400">{t('subscription.basic.yearly.feature_2')}</li>
  
                <li className="p-1 md:p-3 text-gray-400">
                {t('subscription.basic.yearly.feature_3')}
                </li>
              </ul>
            </div>
          </div>
        );
      },
    },
    premium: {
      score: "(Score 12-15)",
      tier1: () => {
        return (
          <div>
            <div className="font-semibold text-base md:text-3xl">$19.98</div>
            <div>
              <ul className="list-disc">
                <li className="p-1 md:p-3 text-gray-400"> {t('subscription.premium.monthly.feature_1')}</li>
                <li className="p-1 md:p-3 text-gray-400"> {t('subscription.premium.monthly.feature_2')}</li>
                <li className="p-1 md:p-3 text-gray-400">
                {t('subscription.premium.monthly.feature_3')}
                </li>
              </ul>
            </div>
          </div>
        );
      },
  
      tier2: () => {
        return (
          <div>
            <div className="font-semibold text-base md:text-3xl">$219.00</div>
            <div>
              <ul className="list-disc">
                <li className="p-1 md:p-3 text-gray-400">{t('subscription.premium.yearly.feature_1')}</li>
                <li className="p-1 md:p-3 text-gray-400">{t('subscription.premium.yearly.feature_2')}</li>
  
                <li className="p-1 md:p-3 text-gray-400">
                {t('subscription.premium.yearly.feature_3')}
                </li>
              </ul>
            </div>
          </div>
        );
      },
    },
  };

  const history = useHistory();
  const { subscriptions } = props;

  const handleRedirect = (subscription, tier) => {
    history.push(
      `/signup?subscription=${subscription}&tier=${subscription}_${tier}`
    );
  };

  return (
    <div className="flex flex-col justify-center mt-20  bg-white px-2 md:px-12">
      <div className="text-center px-8 md:px-12">
        <h2 className="text-3xl font-semibold  text-gray-800 md:text-4xl">
           {t('subscription.pricing')}
        </h2>
      </div>

      <div className="flex flex-wrap w-full mt-12 lg:text-left px-0 md:px-12 ">
        <div className="bg-pink-50 py-4 mt-2 w-full flex flex-items">
          <div className="w-1/4">
            <p className="font-bold text-xs md:text-2xl text-center">{t('subscription.plans')}</p>
          </div>

          <div className="w-3/4 flex flex-items px-8">
            <div className="w-1/2 text-xs md:text-2xl font-semibold">{t('subscription.monthly')}</div>

            <div className="w-1/2 text-xs md:text-2xl font-semibold">{t('subscription.yearly')}</div>
          </div>
        </div>
        {subscriptions
          .map((sub, index) => {
            const currentKey = sub.name.toLowerCase().replace(/ /g, "_");
            return (
              <div
                key={index}
                className={`mt-2 w-full flex flex-items ${index % 2 === 0 ? "bg-green-50" : ""
                  }`}
              >
                <div className="w-1/4 break-words md:border-r-8 md:border-dashed md:border-fuchsia-600">
                  <p className="font-bold text-center text-base md:text-4xl">
                    {t(`subscription.${currentKey}.title`)}
                  </p>

                </div>

                <div className="w-3/4 flex flex-items px-2 md:px-8">
                  <div
                    onClick={() => {
                      handleRedirect(currentKey, "tier_1");
                    }}
                    className="w-full md:w-1/2 text-sm md:text-base cursor-pointer"
                  >
                    {SUBSCRIPTIONS[currentKey] &&
                      SUBSCRIPTIONS[currentKey].tier1()}
                  </div>

                  <div
                    onClick={() => {
                      handleRedirect(currentKey, "tier_2");
                    }}
                    className="w-full md:w-1/2 text-sm md:text-base cursor-pointer"
                  >
                    {SUBSCRIPTIONS[currentKey] &&
                      SUBSCRIPTIONS[currentKey].tier2()}
                  </div>
                </div>
              </div>
            );
          })
          .reverse()}

        <div className="bg-pink-50 py-4 w-full flex flex-items">
          <div className="w-full">
            <p className="font-bold px-12 text-lg md:text-4xl">
            {t('subscription.professional.title')}
            </p>
          </div>
        </div>
      </div>

      <div className="text-center self-center w-9/12 md:w-5/12 py-12">
        <h2 className="text-3xl font-semibold  text-gray-800 ">
        {t('subscription.try_message.title')}
        </h2>
        <p className="text-gray-800">
        {t('subscription.try_message.message')}
        </p>

        <div className="py-8">
          <Link to="/signup" className="cursor-pointer mt-12 px-8 py-4 bg-custom-purple text-lg text-gray-200 text-xs font-light rounded-full">
          {t('subscription.try_message.button')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
