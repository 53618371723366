import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";

// SHARED COMPONENTS
import Modal from "components/Modal";
import Card from "components/Stripe/Card";
import CheckoutForm from "components/Stripe/CheckoutForm";

// REDUX ACTIONS
import {
  requestUpdatePaymentMethod,
  requestUpdatePaymentDetails,
} from "redux/subscription/action";

// UTILS
import {
  addDaysToDate,
  convertTimestamp,
  setCurrencyFormat,
} from "helpers/index";

type Props = {
  user: Nullable<Object>;
};

const PaymentInformation = (props: Props) => {
  const [t] = useTranslation('common');

  const { user } = props;
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [isManagePaymentModalVisible, setIsManagePaymentModalVisible] =
    useState(false);
  const [isAddPaymentMethodVisible, setIsAddPaymentMethodVisible] =
    useState(false);
  const [selectedSource, setSelectedSource] = useState("");
  let currentPaymentCard = [];
  let defaultCard = null;

  useEffect(() => {
    if (user && user.payment_details && user.payment_details.default_source) {
      setSelectedSource(user.payment_details.default_source);
    }
  }, [user]);

  if (user && user.cards && user.cards.data) {
    currentPaymentCard = [...(user.cards.data || [])];
    defaultCard = currentPaymentCard.find((card) => card.id === selectedSource);
  }

  const handleClose = () => {
    if (user && user.payment_details && user.payment_details.default_source) {
      setSelectedSource(user.payment_details.default_source);
    }
    setIsManagePaymentModalVisible(!isManagePaymentModalVisible);
  };

  const handlePaymentEdit = () => {
    setIsAddPaymentMethodVisible(!isAddPaymentMethodVisible);
  };

  const handleUpdatePaymentMethod = async function <T>(e: T) {
    e.preventDefault();
    if (isAddPaymentMethodVisible) {
      const cardElement = elements.getElement(CardElement);
      const card = await stripe.createToken(cardElement);

      const payload = {
        customer_id: user.stripe_id,
        token: card.token.id,
        type: "create",
      };
      dispatch(requestUpdatePaymentMethod(payload));
      setIsManagePaymentModalVisible(!isManagePaymentModalVisible);
    } else {
      const payload = {
        customer_id: user.stripe_id,
        params: {
          default_source: selectedSource,
        },
      };

      dispatch(requestUpdatePaymentDetails(payload));
      setIsManagePaymentModalVisible(!isManagePaymentModalVisible);
    }
  };

  const handleDeleteCard = async (data, type) => {
    const payload = {
      customer_id: user.stripe_id,
      card_id: data.id,
      type,
    };
    dispatch(requestUpdatePaymentMethod(payload));
    setIsManagePaymentModalVisible(!isManagePaymentModalVisible);
  };

  const handleChangeDefaultSource = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setSelectedSource(value);
  };
  return (
    <>
      <div className="flex items-center mb-5 px-4">
        <div className="md:flex md:flex-items max-w-4xl bg-white w-full rounded-lg shadow-md pb-4">
          <div className="w-full md:w-9/12">
            <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 px-4 py-2 ">
              <h2 className="text-1xl font-semibold">{t('profile.subscription.payment_info')}</h2>
            </div>
            <div className=" space-y-1 px-4 py-2 ">
              {user &&
                user.subscription &&
                user.subscription.plan &&
                user.subscription.plan.nickname && (
                  <p className="text-gray-500">
                    {" "}
                    {setCurrencyFormat(
                      user.subscription.plan.amount / 100
                    )} /{" "}
                    {user.subscription.plan.nickname.includes("tier_2")
                      ? "Year"
                      : "Month"}
                  </p>
                )}
              {user && user.subscription && user.subscription && (
                <p className="text-gray-500">
                  {" "}
                  {t('profile.subscription.next_payment')}
                  {addDaysToDate(
                    new Date(
                      convertTimestamp(user.subscription.current_period_end)
                    )
                  )}
                </p>
              )}

              {defaultCard && (
                <p className="text-gray-500">
                  {" "}
                  {defaultCard.brand} ending **** {defaultCard.last4}
                </p>
              )}
            </div>
          </div>
          <div className="w-full md:w-3/12 px-2 py-2 ">
            <div
              onClick={() => {
                setIsManagePaymentModalVisible(true);
              }}
              className="w-full mx-auto cursor-pointer bg-white text-custom-purple border-2 border-custom-purple mb-4 font-bold text-center px-4 py-2 text-xs  font-light rounded-full hover:text-gray-200 hover:bg-custom-purple"
            >
             {t('profile.subscription.manage_payment').toUpperCase()}
            </div>
          </div>
        </div>
      </div>
      <Modal
        handleSave={handleUpdatePaymentMethod}
        handleClose={handleClose}
        isVisible={isManagePaymentModalVisible}
        title={<div className="font-semibold"> {t('profile.subscription.manage_payment')}</div>}
        labelText={t('profile.edit_profile.update')}
      >
        <div className="w-full">
          <div className="w-full">
            <div className="w-full h-auto">
              {isAddPaymentMethodVisible ? (
                <div className="w-full h-8 py-8">
                  <Card />
                </div>
              ) : (
                <div className="w-full">
                  {currentPaymentCard.map((card) => {
                    return (
                      <div className="flex flex-items">
                        <div className="flex flex-items w-9/12">
                          <div className="w-3/12">
                            <input
                              onChange={handleChangeDefaultSource}
                              checked={selectedSource === card.id}
                              type="radio"
                              name="default_source"
                              value={card.id}
                            />
                          </div>
                          <div className="w-9/12">
                            <p className="text-lg">
                              {card.brand} ending ****
                              {card.last4}
                            </p>
                            <p className="text-lg">
                              {t('profile.subscription.expire')}:{" "}
                              {`${
                                card.exp_month < 10
                                  ? `0${card.exp_month}`
                                  : card.exp_month
                              }`}
                              /{card.exp_year}
                            </p>
                          </div>
                        </div>
                        <div className="w-3/12 text-right">
                          <span
                            onClick={() => {
                              handleDeleteCard(card, "delete");
                            }}
                            className="cursor-pointer text-red-700"
                          >
                            X
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <p
              onClick={handlePaymentEdit}
              className="w-full mx-auto mt-6 cursor-pointer bg-white text-custom-purple border-2 border-custom-purple mb-4 font-bold text-center px-4 py-2 text-xs  font-light rounded-full hover:text-gray-200 hover:bg-custom-purple"
            >
              {isAddPaymentMethodVisible ? t('profile.subscription.cancel').toUpperCase() : t('profile.subscription.add').toUpperCase()}
            </p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CheckoutForm(PaymentInformation);
