import { useTranslation } from "react-i18next";
import Quote from "@/assets/svg/quote.svg";

const Testimonials = () => {
  const [t] = useTranslation('common');
  return (
    <div className="flex flex-col mt-20 pb-20 items-center md:px-12">
      <div className="lg:text-left px-8 md:px-12">
        <h2 className="text-3xl font-semibold  text-gray-800 md:text-4xl">
        {t('client_feedback.title')}
        </h2>
      </div>

      <div className="flex flex-wrap w-full  lg:text-left px-8 lg:px-30">
        <div className="h-56 max-h-56  sm:px-4 md:px-5 py-5 w-full lg:w-1/3">
          <img
            alt="avatar"
            className="w-16 rounded-full border-2 border-gray-300 relative top-8 right-4"
            src={Quote}
          />
          <div className="h-56 max-h-56 overflow-auto bg-white border-2 border-gray-100 p-5 rounded-md tracking-wide shadow-lg">
            <div id="header" className="flex">
              <div id="body" className="flex flex-col ml-5">
              <p  className="text-gray-800 mt-2 italic text-sm lg:text-md">
                  "{t('client_feedback.feedback_message_1')}"
                </p><div className="font-semibold italic text-sm text-right">{t('client_feedback.feedback_message_1_author')}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-56 max-h-56  sm:px-4 md:px-5 py-5 w-full lg:w-1/3">
          <img
            alt="avatar"
            className="w-16 rounded-full border-2 border-gray-300 relative top-8 right-4"
            src={Quote}
          />
          <div className="h-56 max-h-56 overflow-auto bg-white border-2 border-gray-100 p-5 rounded-md tracking-wide shadow-lg">
            <div id="header" className="flex">
              <div id="body" className="flex flex-col ml-5">
              <p  className="text-gray-800 mt-2 italic text-sm lg:text-md">
              "{t('client_feedback.feedback_message_2')}"
                </p><div className="font-semibold italic text-sm text-right">{t('client_feedback.feedback_message_2_author')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-56 max-h-56  sm:px-4 md:px-5 py-5 w-full lg:w-1/3">
        <img
          alt="avatar"
          className="w-16 rounded-full border-2 border-gray-300 relative top-8 right-4"
          src={Quote}
        />
        <div className="h-56 max-h-56 overflow-auto bg-white border-2 border-gray-100 p-5 rounded-md tracking-wide shadow-lg">
          <div id="header" className="flex">
            <div id="body" className="flex flex-col ml-5">
              <p  className="text-gray-800 mt-2 italic text-sm lg:text-md">
              "{t('client_feedback.feedback_message_3')}"
                </p>
              <div className="font-semibold italic text-sm text-right">{t('client_feedback.feedback_message_3_author')}</div>


            </div>
          </div>
        </div>
      </div>
    </div>
    </div >
  );
};

export default Testimonials;
