import { useTranslation } from "react-i18next";
// CONSTANTS
import {
  OPTION_TIERS,
  OPTION_SUBSCRIPTION_DETAILS,
} from "constants/subscriptions";

type Props = {
  handleSelectMenu(type: string): void;
  user: Nullable<Object>;
};

const Overview = (props: Props) => {
  const [t] = useTranslation('common');

  const { handleSelectMenu, user } = props;

  const currentSubscription = OPTION_TIERS.find(
    (item) => item.value === user?.subscription?.plan?.nickname
  );

  return (
    <div className="w-full">
      <div className="flex justify-center px-2">
        <div className="max-w-4xl bg-white w-full rounded-lg shadow-md">
          <div className="p-4 border-green-700 border-b">
            <h2 className="text-2xl font-semibold">{t('profile.edit_profile.your_profile')}</h2>
          </div>
          <div>
            <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 px-4 py-2 ">
              <p className="text-gray-600">{t('profile.overview.name')}</p>
              <p className="break-words font-medium">
                {user && user.firstname} {user && user.lastname}
              </p>
            </div>
            <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 px-4 py-2 ">
              <p className="text-gray-600">{t('profile.overview.email')}</p>
              <p className="break-words font-medium">{user && user.email} </p>
            </div>

            <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 px-4 py-2 ">
              <p className="text-gray-600">{t('profile.overview.contact_no')}</p>
              <p className="break-words font-medium">
                {user && user.current_phone_number}
              </p>
            </div>
          </div>
        </div>
      </div>
      {user.subscription && (
        <div className="flex justify-center px-2 mt-4">
          <div className="max-w-4xl bg-white w-full rounded-lg shadow-md py-4">
            <div className="p-4 border-green-700 border-b">
              <h2 className="text-2xl font-semibold">  {t('profile.overview.your_plan')}</h2>
            </div>
            <div>
              <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 px-4 py-2 ">
                <h2 className="text-1xl font-semibold">
                  {currentSubscription ? t(currentSubscription.label) : ''}
                </h2>
              </div>
              <div className=" space-y-1 px-4 py-2 ">
                {OPTION_SUBSCRIPTION_DETAILS[
                  user?.subscription?.plan?.nickname
                ] &&
                  OPTION_SUBSCRIPTION_DETAILS[
                    user?.subscription?.plan?.nickname
                  ].details.map((desc, index) => {
                    return (
                      <p key={index} className="text-gray-500">
                        {" "}
                        - {t(desc)}
                      </p>
                    );
                  })}
              </div>
            </div>
            <div className="px-4">
              <button
                onClick={() => {
                  handleSelectMenu("subscription");
                }}
                className="mx-auto my-4 cursor-pointer bg-white text-custom-purple border-2 border-custom-purple mb-4 font-bold text-center px-4 py-2 text-xs  font-light rounded-full hover:text-gray-200 hover:bg-custom-purple"
              >
                {t('profile.overview.view_details')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
