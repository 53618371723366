import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { requestSignIn } from "redux/auth/action";

import HeaderLogo from "@/assets/header_logo.png";

// TYPES
import { AuthProps } from "types/type";

// UTILS
import { trackEvent } from "helpers/analytics";

interface Inputs {
  email: string;
  password: string;
}

const SignIn = (props: any) => {
  const [t] = useTranslation('common');

  const dispatch = useDispatch();
  const { auth } = useSelector((state: AuthProps) => {
    return {
      auth: state.auth,
    };
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [signInError, setSignInError] = useState<Object | Nullable>(null);

  useEffect(() => {
    if (auth.error) {
      setSignInError(auth.error);
    }
  }, [auth.error]);

  const onSubmit = (data: any) => {
    dispatch(requestSignIn(data));
    trackEvent({});
    
  };

  return (
    <div className="bg-auth p-4 flex flex-wrap justify-center w-full h-screen bg-repeat">
      <div className="mx-auto my-auto sm:px-4 md:px-10 py-5 w-full md:w-3/4 lg:w-2/5 lg:max-w-2/5">
        <div className="bg-white mt-24 border-2 border-gray-100 px-5 py-16 rounded-md tracking-wide shadow-lg">
          <div id="header" className="flex">
            <div id="body" className="flex flex-col w-full px-6">
              <div className="w-full pb-8">
                <img
                  alt="logo"
                  src={HeaderLogo}
                  className="w-36 md:w-44 mx-auto h-auto"
                />
              </div>
              <div className="w-full">
                <div className="flex w-full my-3 text-red-700">
                  {signInError?.message}
                </div>
                <h4 className="font-semibold text-gray-800 md:text-2xl">
                  {t('signin.title')}
                </h4>
              </div>
              {auth.isSignupSuccess && (
                <div className="w-full">
                  <p className="font-semibold text-green-600 md:text-1xl">
                  {t('signup.signup_success')}
                  </p>
                </div>
              )}
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="new-password"
              >
                <div className="w-full mx-auto mt-8">
                  <div className="md-input-main">
                    <div className="md-input-box">
                      <input
                        id="email"
                        type="email"
                        className="md-input"
                        autoComplete="new-password"
                        placeholder=" "
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /\S+@\S+.\S+/,
                            message:
                            t('signup.password_not_match')
                          },
                        })}
                      />
                      <label htmlFor="email" className="md-label">
                      {t('signin.email')}
                      </label>
                      <div className="md-input-underline" />
                    </div>
                    {errors.email && (
                      <span className="text-base text-red-700">
                        {/* This field is required */}
                        {errors.email.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="w-full mx-auto mt-8">
                  <div className="md-input-main">
                    <div className="md-input-box">
                      <input
                        id="password"
                        type="password"
                        className="md-input"
                        autoComplete="new-password"
                        placeholder=" "
                        {...register("password", { required: true })}
                      />

                      <label htmlFor="password" className="md-label">
                      {t('signin.password')}
                      </label>
                      <div className="md-input-underline" />
                    </div>
                    {errors.password && (
                      <span className="text-base text-red-700">
                         {t('signup.required')}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex w-full my-3">
                  <button
                    disabled={auth.isLoading}
                    className="mx-auto w-full cursor-pointer px-8 py-2 bg-custom-purple text-sm text-gray-200 font-light rounded-full"
                  >
                    {auth.isLoading ? t('profile.please_wait') :  t('signin.title').toUpperCase()}
                  </button>
                </div>

                <div className="flex w-full my-3">
                  <span className="mx-auto text-sm font-light">
                    <Link
                      className="cursor-pointer text-custom-purple"
                      to="/resetpassword"
                    >
                       {t('forgot_password.forgot_your_password')}
                    </Link>
                  </span>
                </div>

                <div className="flex w-full my-3">
                  <span className="mx-auto text-sm font-light">
                    {t('signin.dont_have_account')}{" "}
                
                    <Link
                      className="cursor-pointer text-custom-purple"
                      to="/signup"
                    >
                      {t('signin.signup')}
                    </Link>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
