import { useTranslation } from "react-i18next";

import HowItWorks from "@/assets/how_it_works.png";
import Download from "@/assets/svg/download.svg";
import Signup from "@/assets/svg/signup.svg";
import Sync from "@/assets/svg/sync.svg";

const HowAppWorks = (props) => {
  const [t] = useTranslation('common');

  return (
    <>
      <div className="flex bg-how_it_works mt-20 bg-white">
        <div className="justify-center hidden md:w-1/2 md:flex lg:w-1/2">
          <img alt="test" className=" object-scale-down" style={{
            width: '66.666667%',
            height: 431.51
          }} src={HowItWorks} />
        </div>

        <div className="flex sm:w-full px-12 md:px-12 lg:w-1/2 lg:text-left">
          <div>
            <div>
              <h2 className="text-3xl font-semibold text-gray-800 md:text-3xl">
                {t('feature_header.feature_5_title')}
              </h2>
            </div>

            <div className="flex flex-wrap lg:text-left">
              <div className="md:w-1/2 xs:w-full py-12 sm:w-full md:pr-6">
                <img alt="download" className="w-10 h-auto" src={Download} />
                <h5 className="text-1xl font-semibold text-gray-700 md:text-1xl">
                  {t('feature_header.feature_5_item_1')}
                </h5>
                <p className="text-gray-400">
                  {t('feature_header.feature_5_item_1_sub')}
                </p>
              </div>
              <div className="md:w-1/2 xs:w-full py-12 sm:w-full md:pr-6">
                <img alt="sync" className="w-10 h-auto" src={Sync} />
                <h5 className="text-1xl font-semibold text-gray-700 md:text-1xl">
                  {t('feature_header.feature_5_item_3')}
                </h5>
                <p className="text-gray-400">
                  {t('feature_header.feature_5_item_3_sub')}
                </p>


              </div>
              <div className="md:w-1/2 xs:w-full pt-8 pb-12 sm:w-full md:pr-6">
                <img alt="signup" className="w-10 h-auto" src={Signup} />
                <h5 className="text-1xl font-semibold text-gray-700 md:text-1xl">
                  {t('feature_header.feature_5_item_2')}
                </h5>
                <p className="text-gray-400">
                  {t('feature_header.feature_5_item_2_sub')}
                </p>
              </div>
              <div className="md:w-1/2 xs:w-full pt-8 pb-12 sm:w-full md:pr-6">

                <div className="py-8">
                  <a href="/#downloads" className="cursor-pointer mt-12 px-8 py-4 bg-custom-purple text-lg text-gray-200 text-xs font-light rounded-full">
                    {t('download_app')}
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowAppWorks;
