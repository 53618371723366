const actionConstants = {
  REQUEST_SIGN_IN: 'REQUEST_SIGN_IN',
  REQUEST_CURRENT_USER: 'REQUEST_CURRENT_USER',
  REQUEST_SIGN_UP: 'REQUEST_SIGN_UP',
  REQUEST_SIGN_UP_COMPLETE: 'REQUEST_SIGN_UP_COMPLETE',
  REQUEST_SIGN_IN_FAILED: 'REQUEST_SIGN_IN_FAILED',
  REQUEST_SIGN_UP_FAILED: 'REQUEST_SIGN_UP_FAILED',
  REQUEST_LOGOUT:'REQUEST_LOGOUT',
  REQUEST_UPDATE_USER: 'REQUEST_UPDATE_USER',
  REQUEST_CURRENT_USER_PASSWORD: 'REQUEST_CURRENT_USER_PASSWORD',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  REQUEST_UPDATE_FAILED: 'REQUEST_UPDATE_FAILED',
  REQUEST_CURRENT_USER_UPDATE_COMPLETE: 'REQUEST_CURRENT_USER_UPDATE_COMPLETE',
  REQUEST_CHANGE_PASSWORD_COMPLETE: 'REQUEST_CHANGE_PASSWORD_COMPLETE',
  RESET_STATUS_MESSAGE: 'RESET_STATUS_MESSAGE'
}

const	requestSignIn = (data, isAdmin = false) => ({
  type: actionConstants.REQUEST_SIGN_IN,
  data,
  isAdmin
});

const	requestSignInFailed =  (data) => ({
  type: actionConstants.REQUEST_SIGN_IN_FAILED,
  data
});

const	requestSignUp = data => ({
  type: actionConstants.REQUEST_SIGN_UP,
  data
});

const	requestSignUpFailed =  (data) => ({
  type: actionConstants.REQUEST_SIGN_UP_FAILED,
  data
});

const	requestSignUpComplete = () => ({
  type: actionConstants.REQUEST_SIGN_UP_COMPLETE
});

const	requestCurrentUser = () => ({
  type: actionConstants.REQUEST_CURRENT_USER
});

const	setCurrentUser = (data) => ({
  type: actionConstants.SET_CURRENT_USER,
  data
});

const	requestLogout = () => ({
  type: actionConstants.REQUEST_LOGOUT
});

const	requestUpdateUser = (data, updateBy = 'user') => ({
  type: actionConstants.REQUEST_UPDATE_USER,
  data,
  updateBy
});

const	requestUpdateUserPassword = (data) => ({
  type: actionConstants.REQUEST_CURRENT_USER_PASSWORD,
  data
});

const requestUpdateFailed = (error) =>({
  type: actionConstants.REQUEST_UPDATE_FAILED,
  error
});

const requestUpdateUserComplete = () =>({
  type: actionConstants.REQUEST_CURRENT_USER_UPDATE_COMPLETE
});

const requestChangePasswordComplete = () =>({
  type: actionConstants.REQUEST_CHANGE_PASSWORD_COMPLETE
});

const resetStatusMessage = () =>({
  type: actionConstants.RESET_STATUS_MESSAGE
});

export {
  actionConstants,
  requestCurrentUser,
  requestSignIn,
  requestSignInFailed,
  requestSignUp,
  requestSignUpComplete,
  requestLogout,
  requestUpdateUser,
  requestUpdateUserPassword,
  requestUpdateFailed,
  requestSignUpFailed,
  setCurrentUser,
  requestUpdateUserComplete,
  requestChangePasswordComplete,
  resetStatusMessage,
};
