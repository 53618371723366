import axiosInstance from "../index";

const contactRequest = {
  send: (data) => {
    return axiosInstance.post("contact/send", {
      ...data,
    });
  },
};

export default contactRequest;
