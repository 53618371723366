import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Transition } from "@tailwindui/react";
import { useTranslation } from "react-i18next";

import HeaderLogo from "@/assets/header_logo.png";
import User from "@/assets/svg/user-profile.svg";

import "./styles.css";

type Props = {
  isAuthenticated: boolean;
  handleLogOut(): void;
};

const OPTION_LANGUAGE = [
  {
    label: 'English',
    value: 'en'
  },
  {
    label: 'Spanish',
    value: 'es'
  },
  {
    key: 'br',
    label: 'Portuguese',
    value: 'br'
  }
];

const defaultLanguage = localStorage.getItem('mymc_default_language') || 'en';



const HomeHeader = (props: Props) => {
  const [t, i18n] = useTranslation('common');

  const [show, setShow] = useState(false);
  const [isMobileNavbarShow, setIsMobileNavbarShow] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage)
  const container = useRef(null);
  //const mobileNavbar = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (!container?.current?.contains(event.target)) {
        if (!show) return;
        setShow(false);
      }
    };
    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, [show, container]);

  // useEffect(() => {
  //   const handleMobileNavbarClick = (event: MouseEvent) => {
  //     if (!mobileNavbar?.current?.contains(event.target)) {
  //       if (!isMobileNavbarShow) return;
  //       setIsMobileNavbarShow(false);
  //     }
  //   };
  //   window.addEventListener("click", handleMobileNavbarClick);
  //   return window.removeEventListener("click", handleMobileNavbarClick);
  // }, [isMobileNavbarShow, mobileNavbar]);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (!show) return;

      if (event.key === "Escape") {
        setShow(false);
      }
    };

    document.addEventListener("keyup", handleEscape);
    return () => document.removeEventListener("keyup", handleEscape);
  }, [show]);

  const handleChangeLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    i18n.changeLanguage(value);
    localStorage.setItem('mymc_default_language', value);
    setCurrentLanguage(value);
  }

  return (
    <nav className="fixed w-full bg-white z-50">
      <div className="md:flex items-center justify-between py-2 px-8 md:px-12">
        <div className="flex justify-between items-center">
          {/* <div className="flex flex-items cursor-pointer text-2xl font-bold text-gray-800 md:text-3xl"> */}
          <Link className="flex flex-items cursor-pointer text-2xl font-bold text-gray-800 md:text-3xl" to="/#home">
            {/* w-16 md:w-20 h-auto */}
            <img alt="logo" src={HeaderLogo} className="md:ml-12 object-contain" style={{width:80,height:88}} />{" "}
            <span className="mt-4 ml-2">{t('title')}</span>
          </Link>
          {/* </div> */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMobileNavbarShow(!isMobileNavbarShow)}
              aria-controls="mobile-menu"
              aria-expanded="false"
              type="button"
              className="block text-gray-800 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path
                  className="hidden"
                  d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
                />
                <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row hidden md:block -mx-2">
          <Link to="/#home" className="nav-menu">
            {t('menu.home').toUpperCase()}
          </Link>

          <Link to="/#feature" className="nav-menu">
            {t('menu.features').toUpperCase()}
          </Link>
          <Link to="/#steps" className="nav-menu">
            {t('menu.how_to_install').toUpperCase()}
          </Link>

          <Link to="/#subscription" className="nav-menu">
            {t('menu.subscription').toUpperCase()}
          </Link>

          <Link to="/#contacts" className="nav-menu">
            {t('menu.contact_us').toUpperCase()}
          </Link>

          <span className="nav-menu md-input-main">
            <span className="md-input-box">

              <select
                className="text-base"
                placeholder={"Language"}
                onChange={handleChangeLanguage}
              >
                {OPTION_LANGUAGE.map(opt => {
                  return <option
                    selected={currentLanguage === opt.value}
                    key={opt.value}
                    value={opt.value}

                  >
                    {opt.label}
                  </option>
                })}

              </select>
            </span>

          </span>

          {props.isAuthenticated ? (
            <>
              <div
                className="profile-menu bg-white relative top-2"
                ref={container}
              >
                <button
                  className="menu focus:outline-none focus:shadow-solid "
                  onClick={() => setShow(!show)}
                >
                  <img
                    className="w-10 h-10 rounded-full"
                    src={User}
                    alt="user"
                  />
                </button>

                <Transition
                  show={show}
                  enter="transition ease-out duration-100 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="origin-top-right absolute right-0 w-48 py-2 mt-1 bg-white rounded shadow-md">
                    <Link to="/profile">
                      <span className="block px-4 py-2 text-gray-700 hover:bg-custom-purple hover:text-white">
                        {t('profile.title').toUpperCase()}
                      </span>
                    </Link>
                    <span onClick={props.handleLogOut}>
                      <span className="block px-4 py-2 ext-gray-700 hover:bg-custom-purple hover:text-white">
                        {t('profile.logout').toUpperCase()}
                      </span>
                    </span>
                  </div>
                </Transition>
              </div>
            </>
          ) : (
            <span className="py-2 px-4 text-xs text-white bg-custom-purple whitespace-nowrap rounded-full">
              <Link
                to="/signin"

              >
                {t('signin.title').toUpperCase()}
              </Link>
            </span>
          )}
        </div>
        <div
          className={`${isMobileNavbarShow ? "block" : "hidden"
            }  md:hidden -mx-2`}
        >
          <div className="w-full nav-menu text-center  mx-auto p-2">
            <Link to="/"> {t('menu.home').toUpperCase()}</Link>
          </div>
          <div className="w-full nav-menu text-center  mx-auto p-2">
            <Link to="/#feature"> {t('menu.features').toUpperCase()}</Link>
          </div>
          <div className="w-full nav-menu text-center  mx-auto p-2">
            <Link to="/#steps"> {t('menu.how_to_install').toUpperCase()}</Link>
          </div>
          <div className="w-full nav-menu text-center  mx-auto p-2">
            <Link to="/#subscription"> {t('menu.subscription').toUpperCase()}</Link>
          </div>
          <div className="w-full nav-menu text-center  mx-auto p-2">
            <Link to="/#contacts"> {t('menu.contact_us').toUpperCase()}</Link>
          </div>

          {props.isAuthenticated ? (
            <>
              <div className="w-full nav-menu text-center  mx-auto p-2">
                <Link to="/profile"> {t('menu.home').toUpperCase()}</Link>
              </div>
              <div className="w-full nav-menu text-center  mx-auto p-2">
                <span onClick={props.handleLogOut} className="nav-menu">
                  {t('profile.logout').toUpperCase()}
                </span>
              </div>
            </>
          ) : (
            <div className="w-full nav-menu text-center mx-auto p-2">
              <Link to="/signin"> {t('signin.title').toUpperCase()}</Link>
            </div>
          )}
          <div className="w-full nav-menu text-center  mx-auto p-2 md-input-main">
            <div className="md-input-box">
              <select
                placeholder={"Language"}
                onChange={handleChangeLanguage}
                style={{
                  textlign: 'center',
                  textAlignLast: 'center'
                }}
              >
                {OPTION_LANGUAGE.map(opt => {
                  return <option
                    selected={currentLanguage === opt.value}
                    key={opt.value}
                    value={opt.value}

                  >
                    {opt.label}
                  </option>
                })}

              </select>
            </div>

          </div>
        </div>
      </div>
    </nav>
  );
};

export default HomeHeader;
